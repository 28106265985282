import forEdit from "@/mixin/forEdit";

export default {
    name: 'GoodsCategoryEdit',
    mixins: [forEdit],

    data () {
        return {
            // post body 请求体数据
            PB: {
                id: undefined, // 编辑才有id
                level: 0,      // 分类等级
                pid: "",        // 父id
                name: "",      // 分类名称
                title: "",     // 小标题
                isTemp: 2,     // 是否需要模板 1：是 2：否
                isMail: 2,     // 是否需要邮寄
                isShow: 2,     // 是否首页显示
                icon: [],      // 分类图片
                sort: 99,      // 排序
                status: 1,     // 状态：1启用, 2禁用
            },
            rules: {
                level: {
                    required: true,
                    message: "请选择分类等级",
                    trigger: "blur"
                },
                pid: {
                    required: false,
                    message: "请选择父级分类"
                },
                name: {
                    required: true,
                    message: "请填写分类名称",
                    trigger: "blur"
                },
                title: {
                    required: true,
                    message: "请填写小标题",
                    trigger: "blur"
                },
                icon: {
                    required: true,
                    message: "请上传分类图片",
                    trigger: "blur"
                },
                sort: {
                    required: true,
                    message: "填写排序号",
                    trigger: "blur"
                },
            },
            levelOpts: [
                {
                    label: "一级",
                    value: 0
                },
                {
                    label: "二级",
                    value: 1
                }
            ],
            level1Opts: [],    // 一级分类目录
            uploadHeaders: {         // 图片上传的请求头
                token: this.$store.state.token,
                isZip: 1
            },
            uploadData: {
                isZip: 1
            },
            auth: {
                save: false  // 编辑权限
            }
        }
    },

    methods: {

        // 获取页面数据
        fetchData() {
			if (this.loading) return;
			this.loading = true;

			this.$axios({
				url: "api/category/queryCategoryInfo",
				method: "post",
				data: { id: this.PB.id }
			})
            .then(res => {
                if (res.code === 2000) {
                    const {PB} = this, tCategory = res.data.tCategory;
                    for (const key in PB) {
                        if (key == "icon") {
                            PB.icon = [{
                                name: tCategory.icon.slice(tCategory.icon.lastIndexOf("/")+1),
                                url: this.$baseURL + tCategory.icon,
                                path: tCategory.icon
                            }]
    
                        } else if (key == "pid") {
                            PB.pid = tCategory.pid || ""
    
                        } else if (key == "level") {
                            PB.level = tCategory.pid === 0 ? 0 : 1
    
                        } else {
                            PB[key] = tCategory[key]
                        }
                    }

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        getConfig() {
            this.loading = true;
            this.$axios({
                url: "api/category/queryCategoryList",
                method: "post",
                data: {...this.SP}
            })
            .then(res => {
                if (res.code === 2000) {
                    // 格式化 一级分类目录
                    this.level1Opts.splice(
                        0,
                        this.level1Opts.length,
                        ...res.data.categoryList.filter(item => item.pid === 0)
                    )

                } else if (res.code !== 1003) {
                    return Promise.reject(res.msg)
                }
            })
            .catch(reason => {
                this.$message({
                    message: reason || "获取数据失败，请稍后再试",
                    type: "warning"
                }),
                console.warn(reason);
            })

            .then(() => {
                this.loading = false
            })
        },

        handleExceed() {
          // 上传超过一张图
          this.$message({
            message: "只能上传一张图片",
            type: "error",
          });
        },

        beforeFileUpload(file) {
            // 上传图片之前，判断图标格式
            const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 5;
        
            if (!isImage) {
                this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 5MB!');
            }
            if (isImage && isLt2M) {
                // this.userInterface.uploadIconProcess = true;
                this.PB.icon.push(file)
                return true;
            }
            return false;
        },

        handleProgress(e, file) {
            this.posting = true;
            const index = this.PB.icon.indexOf(file);
            this.$set(this.PB.icon, index, file)
        },

        // 上传文件成功
        handleUploadSuccess (res, file) {
            this.posting = false;
            const index = this.PB.icon.indexOf(file);
            if (res.code === 2000) {

                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.PB.icon, index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
                this.$refs.form.clearValidate("img")
            } else {
                this.PB.icon.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },

        // 删除文件
        handleIconDelete (index) {
            this.PB.icon.splice(index, 1);
        },

        // 点击提交按钮
        handleSubmitButton () {

            this.$refs.form.validate()

            .then(() => {
                this.posting = true;
                return this.$axios({
                    url: "api/category/saveCategory",
                    method: "post",
                    data: {
                        ...this.PB,
                        icon: this.PB.icon.map(item => item.path).join(),
                        pid: this.PB.pid || 0
                    }

                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "保存成功" : res.msg || "保存失败",
                    type: success ? "success" : "warn"
                });

                if (success) {
                    this.$router.back()
                }
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: "保存失败",
                    type: "error"
                });
                console.warn(reason);
            })
            
            .then(() => {
                this.posting = false
            })
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        }
    },

    watch: {
        "PB.level": function(val) {
            this.rules.pid.required = !!val
        }
    },

    created () {
        this.getConfig()
		this.auth.save = this.$hasAuthFor("api/category/saveCategory");
    },
}