
import mixinFormSteps from "../mixinForSteps";
let someTimeout;
export default {
    name: 'Step2',
    mixins: [mixinFormSteps],

    data () {
        return {
            PB: {
                id: undefined,        // 商品id（编辑时有，新增时无）
				goodArea: 1,          // 商品区域 1-中国区2-澳洲区
				categoryId: "",       // 分类ID
                isType: 1,            // 规格类型 1单规格  2 多规格
                selectedSpecsId: [],  // 已经选择的大规格id
                tableData: [],        // 根据勾选的属性组合出的列表
            },
            rules: {
				goodArea: {
					required: true,
					message: "请选择商品区域"
				},
				categoryId: {
					required: true,
					message: "请选择商品分类，必须选择二级分类"
				},
                selectedSpecsId: {
                    validator (rule, val, cb) {
                        if (val.length) cb();
                        else cb("请选择属性")
                    }
                },
                tableData: {
                    validator (rule, val, cb) {
                        if ((val instanceof Array) && val.length) cb();
                        else cb("请配置商品的规格属性")
                    }
                },
                goodsNumber: {
                    validator (rule, val, cb) {
                        if (val === "" || Number(val) < 0 || Number(val) > 99999999) cb("请填写合理的数字");
                        else cb();
                    }
                },
                bidPrice: {
                    validator (rule, val, cb) {
                        if (val === "" || Number(val) < 0 || Number(val) > 99999999) cb("请填写合理的数字");
                        else cb();
                    }
                },
                points: {
                    validator (rule, val, cb) {
                        if (val === "" || Number(val) < 0 || Number(val) > 99999999) cb("请填写合理的数字");
                        else cb();
                    }
                },
                payPrice: {
                    validator (rule, val, cb) {
                        if (val === "" || Number(val) < 0 || Number(val) > 99999999) cb("请填写合理的数字");
                        else cb();
                    }
                },
            },
            others: {
                name: "",       // 商品名称
                categoryId1: "",
            },

			// 商品分类选项，及选中的选项id
			category1: [],
			category2: [],
			// categoryId2: "" // PB.categoryId 用作二级分类的模型

			areaList: [
				{
					value: 1,
					label: "中国区服务"
				},
				{
					value: 2,
					label: "澳洲区服务"
				},
			],

            specsList: [],      // 商品可用的大规格集合, 根据商品id查询获得
            specsObj: {},       // 大规格的对象形式，key为id，detailList属性存放其属性，checkedList属性存放其已被勾选的属性
            selectedSpecs: [],  // 已选择的规格（元素为对象形式）

            originDetailList: [],
            listLengthStore: 0, // 记录每一次表格生成后的个数
        }
    },

    computed: {

        isSingle () { // 商品是否单规格
            return this.PB.isType !== 2
        },

        currency () { // 更具商品区域计算币种
            return this.PB.goodArea == 2 ? "澳元" : "人民币"
        },

    },

    methods: {

		// 获取商品分类配置
		fetchCategories (pid = 0) {
			this.$axios({
				url: "api/category/queryCategoryById",
				method: "post",
				data: {pid}
			})

			.then(res => {
				if (res.data?.list) {
					const cate = pid ? this.category2 : this.category1;
					cate.splice(0, cate.length, ...res.data.list)
				} else {
					return Promise.reject(res);
				}
			})

			.catch(reason => console.log(reason))
			
		},

        // 单规格、多规格切换
        handleTypeChange (val) {
            
            this.PB.tableData.splice(0, this.PB.tableData.length); // 清空table

            if (val == 2) { // 多规格
                this.combineEnumList();
            } else { // 单规格
                this.PB.tableData.push(this.initialTableItem())
                // this.$refs.table?.doLayout()
            }
        },

        // 初始化一条新的表格数据
        initialTableItem(item) {
            item = item || {
                goodsName: this.others.name, // 商品名称
                sku: "",
                skuVal: ""
            };
            item.goodsId = this.PB.id;
            item.goodsNumber = 999;
            item.bidPrice = "";
            item.points = "";
            item.payPrice = "";

            return item;
        },

        // 选中一级分类
        handleCategory1 (val, noReset) {
            if (noReset !== true) {
                this.PB.categoryId = "";
                this.category2.splice(0, this.category2.length);
            }
			val && this.fetchCategories(val)
		},
        
        // 根据商品的分类id 获取可选的规格
        async getSpecsByCategory (id, isInitial, detailList) {
            const {selectedSpecsId} = this.PB;
            const originIds = [...selectedSpecsId];
            const originlist = [...this.specsList];
            const loading = this.$loading({
                target: ".list-page > .pd4"
            });
            try {
                id = id || this.PB.categoryId;
                const res = await this.$get(`api/goodsspecs/getSpecsByCategoryId?categoryId=${id}`);
                loading?.close();

                if (res.code === 2000) {
                    const list = res.data.specsList;
                    this.specsList.splice(0, this.specsList.length, ...list.map(item => {
                        item.checkedList = [];
                        const found = originlist.find(({id}) => id == item.id);
                        return found || item;
                    }));

                    // 如果新的规格组选项不是全部包含在已有的选项中，就清空下拉框选中数据
                    let wantRenrender = isInitial === true ? false : true;
                    const isEveryInOrigin = originIds.every(id => list.some(item =>
                        item.id == id));
                    if (!isEveryInOrigin) {
                        if (isInitial !== true)
                            selectedSpecsId.splice(0, selectedSpecsId.length);
                        }
                        else if (!this.originDetailList.isRecovered) {
                            // console.log("recovery", this.originDetailList)
                            // this.PB.tableData.splice(0, this.PB.tableData.length,
                            //     ...this.originDetailList)
                            wantRenrender = false;
                            this.originDetailList.isRecovered = true;
                        }
                    await this.handleSelecteSpecs(selectedSpecsId,
                        wantRenrender,
                        isInitial === true ? detailList : false);


                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                console.warn(reason);
            }
        },

        // 下拉框 当规格选中项改变时，计算选中的规格列表（对象形式，保存到状态中）
        handleSelecteSpecs (val, wantRenrender = true, detailList) {
            // 父组件回显大规格已选中的列表时，可选大规格列表可能还没有回来
            // 也可能是错的，此时延迟执行本函数。
            const _fine = val.every(id=>this.specsList.some(it=>it.id==id));
            if (!this.specsList.length || !_fine) {
                if (someTimeout) clearTimeout(someTimeout);
                someTimeout = setTimeout(() => {
                    this.handleSelecteSpecs(val, wantRenrender, detailList)
                }, 1e3)
                return Promise.resolve();
            }
            someTimeout = null;
            val.sort((p, n) => p - n); // 重新排序
            // debugger;

            /**
             * 根据 id 获取 specsObj 中保存的规格对象， 将其 checkedList 清空
             * 如果 specsObj 没有该对象，这给 specsObj 添加该对象，key 为id，用于
             * 将来根据id获取规格。并添加 checkedList属性，值为一个空数组，用于将
             * 来存放该规格勾选的属性。同时获取规格的属性列表，
             */
            const selectedSpecs = val.map(id => {
                let spec = this.specsObj[id];
                if (spec) {
                    // spec.checkedList.splice(0, spec.checkedList.length);
                    
                } else {
                    spec = this.specsObj[id] = this.specsList.find(item => item?.id == id) || {};
                    this.getSpecsDetail(id)
                }
                return spec;
            });


            if (detailList) for (let detail of detailList) {
                const ids = detail.detail.skuVal.slice(1, -1).split("/").map(id => Number(id));
                ids.forEach((id, index) => {
                    const spec = selectedSpecs[index];
                    if (!spec?.checkedList.includes(id)) {
                        spec?.checkedList.push(id);
                        spec?.checkedList.sort();
                    }
                })
            }

            this.selectedSpecs = selectedSpecs;
            if (wantRenrender && this.PB.isType !== 1) this.combineEnumList();
            return Promise.resolve();
        },

        // 根据规格id获取属性列表
        async getSpecsDetail(id) {
            if (!id) return;

            try {
                const res = await this.$get(`api/goodsspecs/getSpecsDetailById?specsId=${id}`);
                if (res.code === 2000) {
                    this.$set(this.specsObj[id], "detailList", res.data.detailList)
                } else if (res.code !== 1003) {
                    return Promise.reject(res)
                }

            } catch (reason) {
                console.warn(reason);
            }
        },

        isSomeSpecNoChecked () { // 是否有规格没有勾选属性
            const list = this.selectedSpecs;
            if (list.length) {
                return list.some(item => !item.checkedList.length)
            } else {
                return true
            }
        },

        // 当勾选属性时
        handleCheckDetail(val) {
            val.sort((p, n) => p - n); // 重新排序
            this.combineEnumList();
        },

        // 组合选中的属性的二维数组
        combineEnumList () {
            // 如果有空规格，清空表格，并且不组合属性
            if (this.isSomeSpecNoChecked()) {
                this.PB.tableData.splice(0, this.PB.tableData.length);
                this.listLengthStore = 0;
                return;
            }
            const orginTable = [...this.PB.tableData];

            const qArr = []; // 用于存放选中的属性，每一个元素是一个规格中被选中的属性的数组

            for (let spec of this.selectedSpecs) {
                const selectedDetails = spec.checkedList.map(id => {
                    const detail = {
                        ...spec.detailList.find(item => item.id == id),
                        parentName: spec.name
                    };
                    
                    return detail;
                })
                if (selectedDetails.length) qArr.push(selectedDetails)
            }

            if (!qArr.length) return [];

            // 组合选中的属性
            let list = (function doExchange(arr) {
                let len = arr.length;
                // 当数组大于等于2个的时候
                if(len >= 2){
                    // 第一个数组及长度
                    const firstArr = arr[0]; const len1 = firstArr.length;
                    // 第二个数组及长度
                    const seconArr = arr[1]; const len2 = seconArr.length;
                    // 申明一个新数组, 长度为两个数组长度的积
                    let items = new Array(len1 * len2);
                    // 申明新数组的索引
                    let index = 0;
                    for(let i=0; i<len1; i++){
                        for(let j=0; j<len2; j++){

                            const item = firstArr[i].from ? {
                                ...firstArr[i],
                                from: firstArr[i].from.map(item => item)
                            } : {
                                from: [firstArr[i]]
                            };
                            // item.from = item.from || [];
                            item.from.push(seconArr[j]);
                            items[index] = item;
                            index++;
                        }
                    }

                    let newArr = new Array(len -1);
                    for(let i=2;i<arr.length;i++){
                        newArr[i-1] = arr[i];
                    }
                    newArr[0] = items;
                    return doExchange.bind(this)(newArr);

                } else {

                    return arr[0].map(item => {
                        const from = item.from || [item];
                        const sku = `/${this.PB.selectedSpecsId.join("/")}/`;
                        // const newTableItem = this.initialTableItem()
                        return {
                            ...this.initialTableItem(),
                            from,
                            sku,                 // 主规格ids（前后加'/',中间用'/'隔开）
                            skuVal: `/${from.map(f => f.id).join("/")}/`, // 小规格ids（前后加'/',中间用'/'隔开）
                        }
                    });
                }
            }.bind(this)(qArr));

            const _len = list.length;
            if (_len < this.listLengthStore) {
                list = list.filter(({skuVal}) => this.PB.tableData.some(
                    it => it.skuVal == skuVal))
            }
            this.listLengthStore = list.length;

            // console.log(orginTable);
            this.PB.tableData.splice(0, this.PB.tableData.length, ...list.map(item => {
                // console.log(item.skuVal);
                const found = orginTable.find(oit => {
                    const isSame = item.skuVal == oit.skuVal;
                    if (isSame) {
                        // console.log(oit.skuVal);
                        return true
                    } else {
                        return false;
                    }
                    // return item.skuVal == oit.skuVal;
                    // console.log(item, oit);
                    // const olen = oit.from.length;
                    // const nlen = item.from.length;
                    // // console.log(olen, nlen);
                    // if (olen !== nlen) return console.log("len false"), false;

                    // const isSame = oit.from.every((_it, _i) => {
                    //     // console.log(_it.id, item.from[_i].id)
                    //     return _it.id === item.from[_i].id
                    // })
                    // if (!isSame) return console.log("same false"), false;
                    // return true;
                });
                const found1 = this.originDetailList.find(oit => {
                    const isSame = item.skuVal == oit.skuVal;
                    if (isSame) {
                        // console.log(oit.skuVal);
                        return true
                    } else {
                        return false;
                    }
                });
                return found || found1 || item;
            }))
        },

        // 删除规格组合值
        handleDetailDelete (index) {
            this.$confirm("要删除这个规格吗")
            .then(() => this.PB.tableData.splice(index, 1))
        },

        // 填写了进价后，将商品的出售价格设置为进价乘以汇率
        // 并且将商品的兑换积分设置为出售价格的10倍
        handleBidPriceChange (val, data) {
            const ratio = this.PB.goodArea == 2 ? 5 : 1; // 澳币 : 人名币
            data.payPrice = val * ratio;
            if (data.points == "") { data.points = data.payPrice * 10 }
        },

        // 格式话本组件的数据，并返回
        getData () {
            const {goodArea, categoryId, isType, tableData, id} = this.PB;
            // const data = { ...this.PB };
            // delete data.tableData;
            // data.detailList = tableData;
            return {
                goodArea, categoryId, isType, id,
                detailList: tableData.map(item => {
                    const data = { ...item };
                    delete data.from;
                    delete data.goodsName;
                    data.goodsNumber = Number(item.goodsNumber);
                    data.bidPrice = Number(item.bidPrice);
                    data.points = Number(item.points);
                    data.payPrice = Number(item.payPrice);
                    return data;
                })
            }
        },
        changeCheck(data, rowindex, row) { //单选
            if (data) {
                this.PB.tableData.map((item, index) => {
                    if (index !== rowindex) {
                        item.checked = false
                    }
                })
            }
        },
        
        // 回显下拉框选中值，和表格(父组件获取商品详情后调用)
        setExistData (specsList, detailList) {
            console.log(detailList);
            // this.originDetailList = detailList;
            return new Promise((resolve) => {
                if (detailList instanceof Array) {
                    // const _this = this;
                    this.listLengthStore = detailList.length;
                    const {tableData, selectedSpecsId} = this.PB;
                    let flag = selectedSpecsId.length;
                    const list = detailList.map(item => {
                        const { sku, skuVal, goodsId, goodsNumber, bidPrice, points, payPrice, id, checked, isDeal } = item.detail;
                        // 用 sku 数据回显已经选择的大规格
                        if (!flag && sku) {
                            const ids = sku.slice(1, -1).split("/").map(id => Number(id));
                            // setTimeout(() => {
                                selectedSpecsId.push(...ids);
                                // this.handleSelecteSpecs(selectedSpecsId, false, detailList)
                            // }, 2e3)
                            flag = true;
                        } 
    
                        // 将 item 的数据格式话为本地的格式
                        const data = {
                            sku, skuVal, goodsId, goodsNumber, bidPrice, points, payPrice, id, checked, isDeal,
                            from: item.specsDetailList
                        };
                        if (this.PB.isType == 1) data.goodsName = this.others.name
                        return data;
                    });
    
                    // setTimeout(() => { // 延迟防止被 PB.categoryId 的监听器清空
                        this.originDetailList = list;
                        tableData.splice(0, tableData.length, ...list)
                        resolve();
                    // }, 1e3);


                } else {
                    console.log("用远程数据初始化规格表格失败，传入的不是一个数组", detailList)
                    resolve()
                }

            })
            
        },

    },

    created () {
        this.fetchCategories() // 获取商品分类
        const id = Number(this.$route.params.id);
        if (id) {
            this.PB.id = id
        }
        this.PB.tableData.push(this.initialTableItem());
    },

    watch: {

        // 选择了一级分类，获取二级分类的选项
		// "others.categoryId1" (val) {
		// 	this.PB.categoryId = "";
		// 	this.category2.splice(0, this.category2.length);
		// 	val && this.fetchCategories(val)
		// },
        
        // 选择了二级分类，清空已选择的规格
        // "PB.categoryId" (val) {
        //     console.log("====== 二级改变")
        //     this.getSpecsByCategory(val);
        // },

        "others.name" (val) {
            const item = this.PB.tableData[0];
            item.goodsName = val;
            this.$set(this.PB.tableData, 0, item)
            // this.PB.tableData[0].goodsName = val
        },
    }
}