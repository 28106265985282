import forList from "@/mixin/forList";
import EditableState from "@/components/EditableState.vue"
import PreviewGood from "./PreviewGood.vue"
import BindMode from "./BindMode/BindMode.vue"
import WxLink from "./WxLink/WxLink.vue"

export default {
    name: 'GoodsList',
    mixins: [forList],
    components: {
        "editable-state": EditableState,
        "preview-good": PreviewGood,
        "bind-mode": BindMode,
        "wx-link": WxLink
    },

    data () {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {                       // 存储高级搜索面板的临时的查询参数
                fuzzyQueryType: 0,
                fuzzyQueryValue: "",
                categoryPreId: "",       // 商品分类父类
                categoryId: "",          // 商品分类
                goodArea: "",            // 商品区域1-中国区2-澳洲区
                isSale: "",              // 是否上架 1 上架 2 下架
                isNew: "",               // 是否新品 1：是 2：否
                isBest: "",              // 是否首页推荐 1 首页推荐 2 首页不推荐
                isType: "",              // 规格类型 1单规格 2 多规格
                payType: "",             // 支付方式 1-积分 2-微信 3-优惠券支付
            },
            // 表格项（行）的操作菜单
            actionList: [
                {
                    label: "手机预览",
                    name: "preview"
                },
                {
                    label: "编辑",
                    name: "edit"
                },
                {
                    label: "删除",
                    name: "delete"
                },
                {
                    label: "下单时需填写信息",
                    name: "tablemode"
                },
                {
                    label: "获取微信商城链接",
                    name: "link"
                }
            ],
            noActionAvail: false,// 上面4个都不可用，更具用户权限判定，见created

            category1: [],       // 一级分类
            category2: [],       // 二级分类
            payTypeList:[],      // 支付类型集合
            typeList:[],         // 规格类型集合
            searchTypeList: [],  // 搜索条件下拉集合
            areaList:[],         // 区域集合
            whetherList:[],      // 是否通用集合
            wxParams: {},        // wx-link 的props

            operating: false,   // 操作数据中：删除、导出等执行时（通信中）
        }
    },

    computed: {
        currentCustomerSearchType () {
            return this.searchTypeList.find(item => item.value === this.SP_.fuzzyQueryType)
        },

        buttonFreezing () {
            return this.loading || this.operating
        },
        
        bulkFreezing () {
            return this.buttonFreezing || !this.checkedlist.length
        }
    },

    methods: {

        // 获取列表数据
        async fetchData() {
            if (this.loading) return;
            this.loading = true;
            const params = this.extractParams(); // 格式化查询参数

            try {
                const res = await this.$axios({
                    url: "api/goods/colList",
                    params
                });
    
                if (res.code === 2000) {
                    const list = res.data?.list;
                    list && this.tableData.splice(0, this.tableData.length, ...list.map(item => {
                        return {
                            ...item,
                            // 按照 “父分类/子分类” 的格式解析商品的分类信息，用于表格显示
                            category_path: [item.category_pre_name, item.category_name].filter(catename => 
                                !!catename).join("/"),
                            isSale: item.is_sale == "是" ? 1 : 2,
                            isNew: item.is_new == "是" ? 1 : 2,
                            isBest: item.is_best == "是" ? 1 : 2
                        }
                    }));
                    this.total = res.data.total;

                } else if (res.code !== 1003) {
                    return this.$message({
                        message: res.msg || "获取列表失败",
                        type: "warning"
                    })
                }

                this.loading = false;
            } catch (reason) {
                console.warn(reason);
                this.loading = false;
            }

        },

        // 获取配置配置参数
        async fetchConfig() {
            try {
                const res = await this.$axios({ url: "api/goods/getListCond" })
                if (res.code === 2000) {
                    // 把获取的配置列表设为 data 中对应的配置列表
                    for (let key in res.data) {
                        this[key] = res.data[key]
                    }
                } else if (res.code !== 1003) {
                    console.warn("获取参数失败")
                }
            } catch (reason) {
                console.warn(reason)
            }
        },

		// 获取商品分类配置
		fetchCategories (pid = 0) {
			this.$axios({
				url: "api/category/queryCategoryById",
				method: "post",
				data: {pid}
			})

			.then(res => {
				if (res.data?.list) {
					const cate = pid ? this.category2 : this.category1;
					cate.splice(0, cate.length, ...res.data.list)
				} else {
					return Promise.reject(res);
				}
			})

			.catch(reason => console.log(reason))
			
		},

		// 点击 "新增菜单"
		handleAddBtn() {
			this.$router.push("/goods/goods/add");
		},

        handleAction (action, scope) {
            const type = action.name;
            const data = scope.row;
            switch (type) {
                case "preview":
                    this.showPreview(data);
                    break;

                case "edit":
                    this.$router.push(`/goods/goods/edit/${data.id || 5}`);
                    break;

                case "delete":
                    if (data.isSale == 1) {
                        this.$alert("已上架商品不可删除", "提示")
                    } else {
                        this.$confirm("确定要删除商品吗").then(() => 
                            this.postGoodState(data, "delete"))
                    }                    
                    break;

                case "tablemode":
                    this.showmode(data);
                    break;

                case "link":
                    this.showLink(data);
                    break;

                default:
                    break;
            }
        },

        showPreview (data) {
            this.$refs.preview.show(data);
        },

        showmode (data) {
            this.$refs.mode.open(data);
        },

        showLink (data) {
            this.wxParams = {
                path: "pages/goodDetail/goodDetail",
                query: `id=${data.id}`
            }
        },

        handleWxClose () {
            this.wxParams = {}
        },

        // 批量操作：删除、上架、下架
        handleBulkButton (where = "delete", state) {
            // 检查批量删除的商品中有无上架状态的商品，如有，不能执行删除
            let _item;
            if (where === "delete" && this.checkedlist.some(item => {
                if (item.isSale == 1) {
                    _item = item;
                    return true
                }
                return false
            })) {
                return this.$alert(`包含已上架商品，无法批量删除`, "提示");
            }

            const operationName = 
                where == "delete" ? "删除" :
                where == "isSale" ? state == 1 ? "上架" : "下架" :
                "";

            if (operationName) {
                this.$confirm(`确定要${operationName}选中的商品吗`)
                .then(() => this.postGoodState(this.checkedlist, where, state))
            }
        },

        // 发送请求：删除、上架、下架、首页推荐、设为新品
        postGoodState (items, where = "delete", state) {
            this.operating = true;
            const opts = {
                delete: {
                    url: "api/goods/del",
                    name: "删除"
                },
                isSale: {
                    url: "api/goods/changeIsSale",
                    name1: "上架",
                    name2: "下架"
                },
                isNew: {
                    url: "api/goods/changeIsNew",
                    name1: "设为新品",
                    name2: "取消新品"
                },
                isBest: {
                    url: "api/goods/changeIsBest",
                    name1: "设为推荐商品",
                    name2: "取消推荐"
                },
            }
            
            const operation = opts[where];
            const operationName = operation["name"+(state||"")];
            const data = {
                ids: items instanceof Array ? 
                    items.map(item => item.id).join() :
                    items.id,
                state
            }
            this.$axios({ url: operation.url, method: "post", data })
            
            .then(res => {
                const success = 2000 == res.code;
                if (success) {
                    this.$message({
                        message: `${operationName}成功`,
                        type: "success"
                    });
                    this.fetchData();

                } else {
                    return Promise.reject(res)
                }
            })

            .catch(reason => {
                this.$message({
                    message: reason.msg || `${operationName}失败`,
                    type: "warn"
                });
                console.warn(reason)
            })

            .then(() => this.operating = false)

        },
        
        // 选择了一级分类，置空二级分类
        handleCategoryPreChange (val) {
			this.SP_.categoryId = "";
			this.category2.splice(0, this.category2.length);
			val && this.fetchCategories(val)
		}

    },

    created() {
        this.fetchConfig();
        this.fetchCategories();
		this.auth.setSale = this.$hasAuthFor("api/goods/changeIsSale");
		this.auth.setBest = this.$hasAuthFor("api/goods/changeIsBest");
		this.auth.setNew = this.$hasAuthFor("api/goods/changeIsNew");
		this.auth.delete = this.$hasAuthFor("api/goods/del");
		this.auth.preview = this.$hasAuthFor("api/goods/getInfo");
		this.auth.bind = this.$hasAuthFor("api/goods/bindTemp");
		this.auth.unbind = this.$hasAuthFor("api/goods/unboundTemp");
		this.auth.save = this.$hasAuthFor("api/goods/saveGoods");
		this.auth.detail = this.$hasAuthFor("api/goods/queryUpdateInfo");
        if (!this.auth.preview) this.actionList[0].banned = true;
        if (!this.auth.detail) this.actionList[1].banned = true;
        if (!this.auth.delete) this.actionList[2].banned = true;
        if (!this.auth.bind && !this.auth.bind) this.actionList[3].banned = true;
        if (this.actionList.every(item => item.banned)) this.noActionAvail = true;
    }
}