
export default {

    data () {
        return {
            PB: {},           // 表单数据, 保存时需要提交的数据
            rules: {},        // 表单验证规则
            others: {},       // 页面的其他非对象数据
            loading: false,   // 请求状态: 获取
            posting: false,   // 请求状态: 保存
            computing: false, // 计算状态: 计算中
        }
    },
    
    computed: {
        
        // 页面状态：新增商品（!编辑商品）
        isAddPage () {
            return !this.PB.id
        },
    },
    
    methods: {
        validate () {
            return this.$refs.form.validate()
        },

        // 更新 data 数据，(父组件获取商品详情后调用)
        setData (data = {}) {
            for (let key in data) {
                const _key = key.toCamel();
                const value = data[key];

                if (_key in this.PB) {
                    this.PB[_key] = value

                } else if (_key in this.others) {
                    this.others[_key] = value
                }
            }
        },

        // 获取本步骤的数据，（父组件调用）
        getData () {
            console.log(`父组件需要${this.$options.name}的数据，请实现其getData函数`)
            return {}
        }
    }
}