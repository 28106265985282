<template>
    <phone-preview v-if="data" title="预约模板" :ispop="true" :show="show"
        @update:show="handleUpdateShow">
        <template v-if="!data.errorTxt">
            <el-form class="monitor-form" label-position="top">
                <template v-for="item in data.list">
                    <el-form-item v-if="item.viewField && item.type"
                        :key="item.id || item.nanoid"
                        :label="item.viewField" :required="item.isMust == 1"
                    >

                        <!-- 文本框 -->
                        <el-input v-if="item.type == 1" v-model.trim="item.value"></el-input>

                        <!-- 单选 -->
                        <el-radio-group v-if="item.type == 2" v-model="item.value">
                            <el-radio v-for="it in item.optionValue.split('#')"
                                :key="it" :label="it"></el-radio>
                        </el-radio-group>

                        <!-- 复选 -->
                        <el-checkbox-group v-if="item.type == 3" v-model="item.value">
                            <el-checkbox v-for="it in item.optionValue.split('#')"
                                :key="it" :label="it"></el-checkbox>
                        </el-checkbox-group>

                        <!-- 富文本 -->
                        <el-input v-if="item.type == 4" v-model="item.value"
                            type="textarea" rows="4"></el-input>

                        <!-- 日期 -->
                        <el-date-picker v-if="item.type == 5" v-model="item.value"
                            placeholder="YYYY-MM-DD" class="full-width"></el-date-picker>

                        <!-- 时间 -->
                        <el-time-select v-if="item.type == 6" v-model="item.value"
                            placeholder="hh:ss" class="full-width"></el-time-select>

                        <!-- 日期时间 -->
                        <el-date-picker v-if="item.type == 7" v-model="item.value" class="full-width"
                            type="datetime" placeholder="YYYY-MM-DD hh:ss"></el-date-picker>

                        <!-- 上传 -->
                        <el-upload v-if="item.type == 8"
                            class="upload-demo"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :file-list="item.value">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">最多上传{{ item.optionValue }}张图片</div>
                        </el-upload>

                    </el-form-item>
                </template>
                <div class="tips" v-if="data.tips">温馨提示: {{data.tips}}</div>
            </el-form>
        </template>
        <template v-else>
            <div class="error-text">{{ data.errorTxt }}</div>
        </template>
    </phone-preview>
</template>

<script>
import PhonePreview from "@/components/PhonePreview/PhonePreview.vue";
export default {
    name: 'SpecificationPreview',
    props: {
        data: {
            type: Object,
            require: true
        },

        show: {
            type: Boolean,
            default: false
        }
    },
    components: {
        "phone-preview": PhonePreview
    },

    data () {
        return {}
    },

    computed: {},
    methods: {
        handleUpdateShow (val) {
            this.$emit("update:show", val)
        }
    },
}
</script>

<style lang='scss' scoped>

.monitor-form {
    margin: 12px;
    padding: 0 12px;
    min-height: calc(100% - 24px);
    background-color: white;

    .full-width {
        width: 100%;
    }

    .tips {
        padding-bottom: 1em;
        font-size: 12px;
        color: red;
    }
}

.error-text {
    padding-top: 50%;
    text-align: center;
}
</style>