<template>
    <div class="wx-link" v-if="shouldShow">
        <el-dialog :visible="!!link" :title="params.title || title" :width="params.width || width"
            @close="handleCloseBtn">
            <p>{{ link }}</p>
            <el-button @click.stop="handleCopyBtn" class="copy-btn" v-dbclick>复制</el-button>
        </el-dialog>
    </div>
</template>

<script>
import { Loading } from 'element-ui';
export default {
    name: 'WxLink',
    props: {
        params: {
            type: Object,
            default () {
                return {
                    path: "pages/goodDetail/goodDetail", // 页面路径，默认是商品详情页
                    query: "", // 页面参数，"id=1&other=2" 的格式
                }
            }
        }
    },

    data () {
        return {
            title: "微信商城链接", // 弹窗标题
            width: "400px",       // 窗体宽度
            link: "",       // 获取到的短链接
        }
    },

    computed: {
        shouldShow () {
            return !!this.params.path && !!this.params.query;
        }
    },

    watch: {
        shouldShow (show) {
            if (show) {
                this.link = "";
                this.fetchData()
            }
        }
    },

    methods: {
        fetchData () {
            let loadingInstance = Loading.service();
            return this.$axios({
                url: "api/goods/getWeiXinUrlLink",
                method: "post",
                data: {
                    path: this.params.path,
                    query: this.params.query
                }
            })
            .then(res => {
                if (res.code === 2000) {
                    this.link = res.data.urlLink;
                } else if (res.code !== 1003) return Promise.reject(res);
            })
            .catch(reason => {
                console.log("获取链接失败", reason);
                this.$message({
                    message: reason.msg || "获取链接失败"
                })
                this.handleCloseBtn()
            })
            .then(() => {
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    loadingInstance.close();
                });
            })
        },

        // 复制
        handleCopyBtn () {
            const fine = this.link.pushToClipBoard();
            if (fine) this.$message({
                message: "复制成功",
                type: "success"
            });
            else this.$message({
                message: "复制失败, 请手动复制",
                type: "warning"
            })
        },

        handleCloseBtn () {
            console.log("对话框关闭了");
            this.$emit("close")
        }
    }
}
</script>

<style lang='scss' scoped>
    .copy-btn {
        margin-top: 1em;
    }
</style>