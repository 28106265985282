
import mixinFormSteps from "../mixinForSteps";
export default {
    name: 'Step1',
    mixins: [mixinFormSteps],

    data () {
        return {
			PB: {
				id: undefined,    // 商品id（编辑时有，新增时无）
				// goodSn: "",       // 商品编号(编辑时有)
				name: "",         // 商品名称
				longTitle: "",    // 长标题
				isSale: 1,        // 是否上架  1上架 2 下架
				isNew: 2,         // 是否新品  1：是 2：否
				sort: 100,        // 排序
				isBest: 2,        // 是否推荐 1 首页推荐 2 首页不推荐
				payType: 1,       // 支付方式 1-积分 2-微信 3-优惠券支付
				urgentPrice: "",  // 加急费
			},
			rules: {
				name: {
					required: true,
					message: "请输入商品名称"
				},
				longTitle: {
					required: true,
					message: "请输入商品长标题"
				},
				sort: {
					required: true,
					message: "请输入排序号"
				},
			},
			others: {
				goodSn: "",       // 商品编号(编辑时有)
			}
		}
    },

    methods: {

        // 格式话本组件的数据，并返回
        getData () {
            return {
				...this.PB,
				
			}
        }
    },

    // created () {
    //     this.fetchCategories()
    // },

	// watch: {
	// 	categoryId1 (val) {
	// 		this.PB.categoryId = "";
	// 		this.category2.splice(0, this.category2.length);
	// 		val && this.fetchCategories(val)
	// 	}
	// }
}