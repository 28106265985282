import forEdit from "@/mixin/forEdit"
import phoneMonitor from "../SpecificationPreview/SpecificationPreview"
import {nanoid} from "nanoid" // nanoid() 可以生成一个唯一的字符串 => 类似于"V1StGXR8_Z5jdHi6B-myT"

const createNewItem = () => ({
    id: 0,
    viewField: "",
    isMust: 2,
    sort: 99,
    type: 1,
    optionValue: "",
    value: "", // 用于模拟器中的表单控件的绑定值，避免报错
    nanoid: nanoid()
});

export default {
    name: 'GoodsTableModeEdit',
    mixins: [forEdit],
    components: {
        "phone-monitor": phoneMonitor
    },

    data () {
        return {
            // post body 请求体数据
            PB: {
                id: undefined,    // 编辑才有id
                name: "",         // 模板名称
                tips: "",         // 温馨提示
                tTempDetails: [], // 字段配置
            },
            rules: {
                name: {
                    required: true,
                    message: "请输入模板名称"
                },

                // 下面是字段配置里的验证
                viewField: {
                    required: true,
                    message: "请输入字段名称"
                },
                sort: {
                    required: true,
                    message: "请输入排序号"
                },
                type: {
                    required: true,
                    message: "请选择值的类型"
                },
                optionValue: [
                    // { required: true, message: "请配置属性" },
                    {
                        /**
                         * 当修改一个字段的type 为日期、上传文件时，optionValue 需
                         * 要填写为数字。而此时这个如果值恰好为一个字符串，它就会绕过
                         * 第一条必填检测，但并没有提供需要的数字值。所以增加这条验证
                         */
                        validator: function(rule, val, cb) {
                            const index = Number(rule.field.match(/\d+/)[0]);
                            const type = this.PB.tTempDetails[index].type;
                            switch (type) {
                                // 2单选，3复选
                                case 2:
                                case 3:
                                    if (/^[a-zA-Z0-9_()（）\u2E80-\u9FFF]+(#[a-zA-Z0-9_()（）\u2E80-\u9FFF]*)*$/.test(val)) cb();
                                    else cb('请配置选项, 以"#"分隔')
                                    break;

                                // 5日期，7日期时间
                                case 5:
                                case 7:
                                    if ((/^\d+$/.test(val))) cb();
                                    else cb("请配置提前天数")
                                    break;

                                // 8上传文件
                                case 8:
                                    if ((/^\d+$/.test(val))) cb();
                                    else cb("请输入数量上限")
                                    break;
                            }
                        }.bind(this)
                    }
                ]
            },
            whetherEnum: [],         // 是否与对应值的字典
            tempDetailTypeEnum: [],  // 下拉与对应值的字典
            monitorShow: false,      // 预览窗状态
            auth: {                  // 用户权限，见created
                save: false
            },
        }
    },

    computed: {
        monitorData () {
            return {
                list: [...this.PB.tTempDetails].sort((p, n) => (p.sort || 0) - (n.sort || 0)),
                tips: this.PB.tips
            }
        }
    },

    methods: {

        // 获取页面数据，withoutProfile，只要配置信息
        fetchData(withoutProfile) {
			if (this.loading) return;
			this.loading = true;

			this.$axios({
				url: "api/ordertemp/queryOrderTempInfo",
				method: "post",
				data: { id: this.PB.id }
			})
            .then(res => {
                if (res.code === 2000) {
                    this.whetherEnum.splice(
                        0,
                        this.whetherEnum.length,
                        ...res.data.whetherEnum
                    );
    
                    this.tempDetailTypeEnum.splice(
                        0,
                        this.tempDetailTypeEnum.length,
                        ...res.data.tempDetailTypeEnum
                    );
    
                    if (!withoutProfile) {
                        const {PB} = this,
                            tOrderTemp = res.data.tOrderTemp,
                            detaillist = res.data.detaillist;
                        for (const key in PB) {
                            switch (key) {
                                case "tTempDetails":
                                    PB.tTempDetails.splice(0, PB.tTempDetails.length,
                                        ...detaillist.map(item => {
                                            return {
                                                id: item.id,
                                                viewField: item.view_field,
                                                isMust: item.is_must,
                                                sort: item.sort,
                                                type: item.type,
                                                optionValue: item.option_value,
                                                // value 用于模拟器（预览）中的控件绑定值，否则会报错
                                                value: item.type == 3 || item.type == 8 ? [] : ""
                                            }
                                        }))
                                    break;
    
                                default: 
                                    PB[key] = tOrderTemp[key];
                                    break;
                            }
                        }
                    }

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        // type 改变时，重新验证optionValue
        handleTypeChange (e, index) {
            this.$refs.form.validate("optionValue")
                .catch(reason => console.log(reason));
            
            // value 用于模拟器（预览）中的控件绑定值，否则会报错
            if (e == 3 || e == 8) this.PB.tTempDetails[index].value = [];
            else this.PB.tTempDetails[index].value = "";
        },

        // 点击按钮: "添加字段"
        handleAddBtn() {
            // this.$refs.form.validate()

            // .then(this.addItem)
            // .catch(reason => {
            //     this.$message({
            //         message: "请填写完整再添加新字段",
            //         type: "warning"
            //     })
            //     console.warn(reason)}
            // )
            this.addItem()
        },

        // 新增一个字段
        addItem() {
            this.PB.tTempDetails.push(createNewItem())
        },

        // 点击删除按钮
        handleRemoveBtn(index) {
            this.$confirm("要移除这个字段吗")
                .then(() => {
                    const list = this.PB.tTempDetails;
                    let data = list[index];
                    list.splice(index, 1);
                    if (data.id) {
                        this.$message({
                            message: "记得点击保存，使移除生效",
                            type: "warning"
                        });
                        data = undefined;
                    }
                })
                .catch(reason => console.warn(reason))
        },

        // 点击提交按钮
        handleSubmitButton () {

            this.$refs.form.validate()

            .then(() => {
                this.posting = true;
                
                return this.$axios({
                    url: "api/ordertemp/saveOrderTemp",
                    method: "post",
                    data: {
                        ...this.PB,
                        tTempDetails: JSON.stringify(this.PB.tTempDetails.map(item => {
                            // 移除自增字段的本地id
                            const _item = {...item};
                            delete _item.nanoid;
                            delete _item.value;
                            return _item
                        }))
                    }

                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "保存成功" : res.msg || "保存失败",
                    type: success ? "success" : "warn"
                });

                if (success) {
                    this.handleNavigationBack()
                }
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: "保存失败",
                    type: "error"
                });
                console.warn(reason);
            })
            
            .then(() => {
                this.posting = false
            })
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        },

        handlePreview() {
            this.monitorShow = true
        }
    },

    created () {
        // 有 id 的情况，在 @/mixin/forEdit 已经处理了，
        // 这个没有 id 也发送请求的目的是获取 岗位、系统 的配置信息
        const id = Number(this.$route.params.id);
        if (!id) {
            this.fetchData(true);
            this.addItem()
        }
		this.auth.save = this.$hasAuthFor("api/ordertemp/saveOrderTemp");
    }
}