import forList from "@/mixin/forList";
import phoneMonitor from "../SpecificationPreview/SpecificationPreview"

export default {
    name: 'GoodsTableMode',
    mixins: [forList],
	components: {
		"phone-monitor": phoneMonitor
	},

    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                sreachname: "",   // 搜索名称
            },
            monitorShow: false,   // 预览窗状态
			detailLoading: false, // 预览窗信息加载中
			monitorData: null,    // 预览窗的数据
        };
    },

    methods: {

		// 获取列表数据
		fetchData() {
			if (this.loading) return;
			this.loading = true;

			this.$axios({
				url: "api/ordertemp/queryOrderTempList",
				method: "post",
				data: {...this.extractParams()}
			})
			.then(res => {
				if (res.code === 2000) {
					
					this.total = res.data.count;
					const list = res.data.orderTempList.map(item => {
						return {
							...item
						}
					});
					this.tableData.splice(0, this.tableData.length, ...list);

				} else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},

        // 点击 "添加订单模板"
        handleAddBtn() {
            this.$router.push("/goods/tablemode/add");
        },

        // 点击表格行操作按钮: 编辑
        handleEdit(data) {
            this.$router.push("/goods/tablemode/edit/" + (data.id || 5));
        },

        // 点击表格行操作按钮: 删除
        handleDelete(data) {
			this.$confirm("要删除这个模板吗")

                .then(() => {
					this.loading = true;

					return this.$axios({
						url: "api/ordertemp/deleteOrderTemp",
						method: "post",
						data: { id : data.id }
					})
				})

				.then(res => {
					const success = 2000 == res.code;

					this.$message({
						message: success ? "删除成功" : (res.msg || "删除失败"),
						type: success ? "success" : "warning"
					})

					this.loading = false;
					if (success) this.fetchData();
				})

                .catch(reason => {
                    console.warn(reason);
					this.loading = false;
                })
        },

        // 点击：预览模板
        handlePreview (data) {

			this.monitorData = {
				list: [],
				tips: "",
				errorTxt: ""
			}
			this.monitorShow = true;
			this.detailLoading = true;

			this.$axios({
				url: "api/ordertemp/queryOrderTempInfo",
				method: "post",
				data: { id: data.id }
			})

			.then(res => {
				const success = 2000 == res.code;

				if (success) {
					const list = res.data.detaillist.map(item => {
						return {
							id: item.id,
							viewField: item.view_field,
							isMust: item.is_must,
							sort: item.sort,
							type: item.type,
							optionValue: item.option_value,
							// value 用于模拟器（预览）中的控件绑定值，否则会报错
							value: item.type == 3 || item.type == 8 ? [] : ""
						}
					}).sort((p, n) => p.sort - n.sort)
					this.monitorData.tips = data.tips;
					this.monitorData.list.push(...list);		
					
				} else {
					return Promise.reject(res.msg)
				}
			})

			.catch(reason => {
				this.$message({
					message: "获取模板数据失败，请稍后重试"
				});
				console.warn(reason);
				this.monitorData.errorTxt = "获取模板数据失败，请稍后重试";
			})

			.then(() => {
				this.detailLoading = false
			})
        },

		// 模拟器发送的关闭事件
		handleUpdateShow () {
			this.monitorShow = false;
			this.monitorData = null;
		}

    },

	created () {
		this.auth.save = this.$hasAuthFor("api/ordertemp/saveOrderTemp");
		this.auth.detail = this.$hasAuthFor("api/ordertemp/queryOrderTempInfo");
		this.auth.delete = this.$hasAuthFor("api/ordertemp/deleteOrderTemp");
	}
}