<!-- 展示预约模板的单选列表，并在选中后，触发change事件。
    同时，提供搜索框，用于搜索模板名称 -->
<template>
    <div>
        <div class="search-bar">
            <el-input v-model.trim="key" @keyup.enter.native="handleSearchBtn"
                placeholder="请输入模板名称搜索模板">
                <el-button slot="append" icon="el-icon-search"
                    @click="handleSearchBtn"></el-button>
            </el-input>
            <el-button @click="handleClearBtn">清空</el-button>
        </div>

        <el-radio-group v-model="active" @change="handleChange" v-if="list.length">
            <el-radio v-for="item in list" :key="item.id"
                :label="item.id">{{ item.name }}</el-radio>
        </el-radio-group>

        <div v-else class="empty-txt">未找到相关模板</div>
    </div>
</template>

<script>
export default {
    name: 'ModeSelector',

    props: {
        default: Number, // 初始选中的模板项的值（id）
    },

    data () {
        return {
            loading: false, // 状态：获取远程数据中
            key: "",        // 搜索关键词
            listAll: [],    // 模板列表（所有）
            list: [],       // 根据关键词筛选后的列表
            active: "",     // 当前选中的值（id）
        }
    },

    computed: {

        // 初始选中的模板项
        defaultActive () {
            return this.listAll.find(item => item.id === this.default)
        }
    },

    methods: {
        // 发送请求获取数据
        async fetchData() {
            // console.log("fetch 模板列表...");
            this.loading = true;
            try {
                const res = await this.$get("api/ordertemp/getListByName?name=");
                if (res.code === 2000) {
                    const {listAll} = this;
                    const list = res.data.tempList.filter(item => item != null);
                    listAll.splice(0, listAll.length, ...list)
                } else if (res.code !== 1003) {
                    throw new Error(res)
                }
                this.loading = false;

            } catch (reason) {
                console.warn(reason);
                this.loading = false;
                return Promise.reject()
            }
        },

        // 点击搜索按钮
        handleSearchBtn () {
            if (this.listAll.length) this.doSearch();
        },

        // 执行数据过滤（按关键词）
        doSearch (key) {
            key = key || this.key.trim();
            const {list, listAll} = this;
            let _list = listAll;

            if (key.length) {
                _list = this.listAll.filter(item => 
                    item.name.includes(key));
            }

            list.splice(0, list.length, ..._list)
        },

        // 清空
        handleClearBtn () {
            this.key = "";
            this.doSearch();
        },

        handleChange (id) {
            // console.log(item)
            const item = this.listAll.find(item => item.id == id) || null
            this.$emit("change", id, item)
        },

        reset () {
            this.active = "";
            this.handleChange ("");
        }

    },

    created () {
        this.fetchData().catch(() => this.fetchData())
        .then(this.doSearch)
    }
}
</script>

<style lang='scss' scoped>
    .search-bar {
        display: flex;
        .el-input {
            flex: 1;
            margin-right: 10px;
        }
    }

    .el-radio-group, .empty-txt {
        display: block;
        height: auto;
        padding: 10px 0;
    }
    .el-radio {
        display: block;
        margin-top: 24px;

    }

    .empty-txt {
        text-align: center;
        opacity: .5;
    }
</style>