<template>
    <ul class="steps-bar" :class="className">
        <li v-for="(item, index) in steps"
            :class="{active: activeIndex === index, finish: activeIndex > index}"
            :key="item" @click.stop="handleStepClick(index)">

            <template v-if="navigator">{{ item }}</template>
            <template v-else>{{ index + 1 }}. {{ item }}</template>
            
        </li>
    </ul>
</template>

<script>
export default {
    name: 'StepsBar',
    props: {
        steps: {
            type: Array,
            default: () => ["步骤1", "步骤2"]
        },

        active: {
            type: Number,
            default: 1
        },

        navigator: { // 是否可点击切换当前步骤
            type: Boolean,
            default: false
        }
    },

    computed: {
        activeIndex () {
            return this.active - 1
        },

        className () {
            return this.navigator ? "is-navigator" : "not-navigator"
        }
    },

    methods: {
        handleStepClick (index) {
            const {navigator, activeIndex} = this;
            if (navigator && (index !== activeIndex)) {
                this.$emit("update:active", index + 1)
            }
        }
    }
}
</script>

<style lang='scss' scoped>

    $color: #f5f5f5;
    $active-color: #CD944D;
    $berder-color: #EBEDF0;
    $net-height: 46px; // 高度，content-box

    ul {
        display: flex;
        align-items: center;
        border: 1px solid transparent;

        li {
            position: relative;
            text-align: center;
            + li {
                margin-left: 24px;
            }
        }
    }

    .not-navigator { // 新增
        $arrow-width: 20px;
        border-color: $berder-color;
        border-radius: 2px;
        overflow: hidden;

        li {
            flex: 1;
            line-height: $net-height;
            background-color: $color;
            &.active,
            &.finish {
                background-color: $active-color;
                color: white;
                border: 0;
            }

            + li {
                &::before {
                    content: "";
                    position: absolute;
                    left: -$arrow-width; top: 0; bottom: 0;
                    border-left: $arrow-width solid transparent;
                    border-top: $net-height/2 solid $color;
                    border-bottom: $net-height/2 solid $color;
                }
                &.active::before,
                &.finish::before {
                    border-top-color: $active-color;
                    border-bottom-color: $active-color;
                }
            }

            &:not(:last-child) {
                &::after {
                    content: "";
                    position: absolute;
                    right: -$arrow-width; top: 0; bottom: 0;
                    border-left: $arrow-width solid $color;
                    border-top: $net-height/2 solid transparent;
                    border-bottom: $net-height/2 solid transparent;
                }
                &.active::after,
                &.finish::after {
                    border-left-color: $active-color;
                }
                
            }
        }
    }

    .is-navigator { // 编辑
        border-bottom-color: $berder-color;

        li {
            padding-bottom: 16px;
            border-bottom: 2px solid transparent;
            cursor: pointer;
            transition: border-color .5s;

            &:hover {
                border-bottom-color: rgba($color: $active-color, $alpha: .25);
            }

            &.active {
                color: $active-color;
                border-bottom-color: $active-color;
            }
        }
    }
</style>