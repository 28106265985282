<!-- 缩略图列表，展示为一排缩略图，一般用于上传图片或显示一组图片时 -->

<template>
    <ul class="thumbnail-list" v-if="listAlready">

        <template v-for="(image, index) in images">
            <!-- 如果数据的 percentage 属性为小于100的数字，则显示一个进度动画 -->
            <li v-if="image.percentage < 100" :style="sizeStyle"
                :key="image.name || image.path || image">

                <el-progress :percentage="parseInt(image.percentage) || 0" type="dashboard"></el-progress>
            </li>

            <!-- 否则，即显示图片 -->
            <li v-else
                :key="image.path || image.name || image.url || image"
                :style="sizeStyle + 'background-image: url(' + (image.url || image.path || image) + ')'"
                @click.stop="handleThumbClick(index)">

                <i class="el-icon-delete" v-if="showDelete"
                    @click.stop="handleDeleteClick(index)"></i>
            </li>

        </template>
    </ul>
</template>

<script>
// import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'
// import Vue from 'vue'
// Vue.use(VueViewer, {
//     defaultOptions: {
//         zIndex: 99999
//     }
// })

export default {
    name: 'ThumbnailList',
    props: {
        images: {
            type: Array,
            require: true,
            default: function () {
                return []
            }
        },
        showDelete: {
            type: Boolean,
            default: false
        },
        read: { // 可查看大图
            type: Boolean,
            default: true
        },
        size: Number
    },

    computed: {
        listAlready () {
            return (this.images instanceof Array) && this.images.length
        },

        sizeStyle () {
            const size = this.size || (this.showDelete ? 120 : 46);
            return `width: ${size}px; height: ${size}px;`;
        }
    },

    methods: {

        handleThumbClick(index) {
            if (!this.read) return this.$message({
                message: "您没有查看权限",
                type: "warning"
            });
            // this.$hevueImgPreview(image.url || image.path || image)
            this.$viewerApi({
                images: this.images.map(item => item.url || item.path || item),
                options: {
                    initialViewIndex: index
                }
            })
        },

        handleDeleteClick(index) {
            this.$confirm("要删除这张图片吗", "提示")

            .then(() => {
                this.$emit("delete", index)
            })

            .catch(reason => {
                console.log("取消删除", reason)
            })
        }
    },
}
</script>

<style lang='scss' scoped>
    ul {
        display: flex;
        flex-wrap: wrap;
        min-width: 100%;
        margin-right: -10px;
        margin-bottom: -10px;
        
        &:nth-child(n+2) {
            margin-top: 10px;
        }
    }
    
    li {
        // width: 46px;
        // height: 46px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 2px;
        background-color: whitesmoke;
        background-size: cover;
        background-position: center;

        &:not(.unclickable) {
            cursor: pointer;
        }

        // &.show-delete {
            // width: 120px;
            // height: 120px;
            position: relative;
        // }

        &:hover i {
            opacity: 1;
        }

        // + li {
        //     margin-left: 10px;
        // }
    }
    
    i {
        position: absolute;
        top: 0; right: 0;
        padding: 6px;
        background-color: rgba($color: white, $alpha: .75);
        border: 1px solid rgba($color: #000000, $alpha: .2);
        border-radius: 0 0 0 6px;
        font-size: 16px;
        opacity: 0;
        transition: opacity .25s;
    }
    
</style>