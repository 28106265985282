<template>
    <phone-preview title="商品详情" :ispop="true" :show="preview"
        @update:show="handleClose">
        <div class="content" v-loading="loading">

            <!-- 幻灯片 -->
            <el-carousel height="160px">
                <el-carousel-item v-for="path in profile.img_paths"
                    :key="path" class="banner-item"
                    :style="`background-image: url(${$baseURL+path})`">
                </el-carousel-item>
            </el-carousel>

            <div class="head-group pad-l pad-r">
                <!-- 标题 -->
                <h3>{{ profile.name }}</h3>
                <h4 v-if="profile.long_title">{{ profile.long_title }}</h4>
                <div class="sn">商品编号: {{ profile.good_sn }}</div>
                <div class="price">
                    <span>{{ profile.minpay }} - {{ profile.maxpay }}</span>
                    <i>{{ profile.pay_type_name }}</i>
                </div>
            </div>

            <!-- 规格 -->
            <el-collapse class="spec-selector">
                <el-collapse-item>
                    <div slot="title" class="selected-notice pad-l">
                        <span>已选: {{selectedStr}}</span>
                        <span>数量: {{selectedNum}}</span>
                    </div>

                    <div v-for="spec in specsList" :key="spec.id"
                        class="spec-item pad-l">
                        <em>{{ spec.name }}: </em>
                        <ul class="detail-list">
                            <li v-for="detail in spec.details" :key="detail.id"
                                :class="{active: detail === spec.active}"
                                @click="handleDetailClick(spec, detail)">
                                {{ detail.value }}
                            </li>
                        </ul>
                    </div>

                    <div class="mt4 pad-l">数量: 
                        <el-input-number v-model="selectedNum" :min="1"></el-input-number>
                    </div>
                </el-collapse-item>
            </el-collapse>

            <!-- 内容 -->
            <div class="detail" v-html="profile.desc"></div>
        </div>
    </phone-preview>
</template>

<script>
import PhonePreview from "@/components/PhonePreview/PhonePreview.vue"
export default {
    name: 'PreviewGood',
    components: {
        "phone-preview": PhonePreview
    },
    
    data () {
        return {
            payTypeList: [], // 支付类型集合
            specsList: [],   // 大规格集合
            // specsDetail: {}, // 规格属性集合
            profile: {},     // 商品信息
            preview: false,  // 手机预览窗显示状态
            loading: false,
            selectedNum: 1,
        }
    },

    computed: {
        selectedStr () {
            if (this.profile.is_type == 1) {
                return this.profile.name
            } else {
                const str = this.specsList.reduce((str, item) => str + item.active.value + ",", "");
                return str.slice(0, -1);
            }
        }
    },

    methods: {

        // 显示商品的预览信息（父组件调用）
        async show (item) {
            this.preview = true;
            let id = typeof item == "object" ? item.id : item;
                id = Number(id);
            this.profile.id = id;
            this.loading = true
            try {
                await this.fetchData(id);
                this.loading = false;

            } catch (reason) {
                this.$message({
                    message: "获取商品信息失败",
                    type: "warning"
                })
                console.warn(reason);
                this.handleClose();
                this.loading = false;
            }
        },

        // 发送请求，获取商品信息
        fetchData (id) {
            if (!id) throw new Error("没有传入id, 不能获取商品信息");
            return new Promise((resolve, reject) => {
                this.$get(`api/goods/getInfo?id=${id}`)
                .catch(() => this.$get(`api/goods/getInfo?id=${id}`))

                .then(res => {
                    const success = 2000 === res.code;
                    if (success) {
                        const {goods} = res.data;
                        // 如果已经关闭，profile 应该已经被清空
                        if (goods.id == this.profile?.id) {
                            const {payTypeList, specsList} = res.data;
                            this.profile = {
                                ...goods,
                                img_paths: goods.img_paths.split(",").filter(item => !!item),
                                pay_type_name: payTypeList.find(type => type.value == goods.pay_type)?.name || "元",
                                desc: goods.desc.addHostToImg(this.$baseURL.slice(0, -1))
                            };
                            // this.specsDetail = res.data.specsDetail;
                            
                            this.payTypeList.splice(0, this.payTypeList.length,
                            ...payTypeList.filter(item => item!=null));

                            this.specsList.splice(0, this.specsList.length,
                                ...specsList.filter(spec => {
                                    if (spec == null) {
                                        return false
                                    } else {
                                        const details = res.data.specsDetail[spec.id];
                                        spec.details = details;
                                        spec.active = details[0];
                                        return true;
                                    }
                                })
                            );
                        }
                        resolve()
                    } else {
                        reject(res)
                    }
                })
                .catch(reason => {
                    reject (reason);
                    this.loading = false;
                })
            })
        },

        // 点击关闭按钮
        handleClose () {
            this.resetState();
            this.preview = false;
        },

        // 重置所有是数据
        resetState () {
            this.goods = {};
            // this.specsDetail = {};
            this.selectedNum = 1;
            const {payTypeList, specsList} = this;
            payTypeList.splice(0, payTypeList.length);
            specsList.splice(0, specsList.length);
        },

        // 点击选择属性
        handleDetailClick(spec, detail) {
            spec.active = detail
        } 

    }
}
</script>

<style lang='scss' scoped>
    .content {
        min-height: 100%;
        background-color: white;

        .pad-l {
            padding-left: 10px;
        }
        .pad-r {
            padding-right: 10px;
        }
    }

    .banner-item {
        background-color: #f0f0f0;
        background-size: cover;
        background-position: center;
    }

    .head-group {
        margin: 1em auto;

        h3 {
            font-size: 1em;
        }

        h4 {
            margin: 10px auto;
            font-size: 12px;
            font-weight: normal;
            line-height: 1.4;
        }

        .sn {
            margin: 10px auto;
            color: gray;
        }

        .price {
            display: flex;
            align-items: center;
            span {
                font-size: 16px;
                color: indianred;
                font-weight: bold;
            }
            i {
                margin-left: 6px;
                font-size: 12px;
            }
        }
        
    }

    .spec-selector {
        margin: 1em auto;
    }

    .selected-notice {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        margin-right: 1em;
    }

    .spec-item {
        em {
            font-weight: bold;
        }
    }

    .detail-list {
        display: flex;
        flex-wrap: wrap;
        
        li {
            margin-right: 10px;
            padding: 0 10px;
            border: 1px solid rgba($color: #000000, $alpha: .1);
            border-radius: 2px;
            &.active {
                border-color: indianred;
            }
        }
    }

    /deep/ .el-collapse-item__content {
        padding-bottom: 10px;
    }

    .detail {
        margin: 10px;
        /deep/ img {
            max-width: 100%;
        }
    }
</style>