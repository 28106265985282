
export default {
    name: 'PhonePreview',
    props: {

        title: { // 手机预览的标题
            type: String,
            default: "终端预览"
        },

        ispop: { // 是否已弹出层的模式展示
            type: Boolean,
            default: false
        },

        show: { // 弹出层模式下，控制显示与隐藏
            type: Boolean,
            default: false
        }

    },

    data () {
        return {}
    },

    computed: {},
    methods: {
        
        // 点击关闭按钮
        handleCloseBtn () {
            this.$emit("update:show", false)
        }
    },
    created () {},
    beforeMount () {},
    mounted () {},
    components: {},
    watch: {}
}