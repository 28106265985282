import forList from "@/mixin/forList";

export default {
    name: 'Specification',
    mixins: [forList],

    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                searchname: "",  // 搜索名称
            },
			class1: "",          // 选中的一级分类
			class2: "",          // 选中的二级fenl
			category1: [],       // 一级分类选项
			category2: [],       // 二级分类选项
			
        };
    },
	
	computed: {
		searchcategoryid () { // 搜索分类
			return this.class2 || this.class1
		},

	},

    methods: {

		// 点击 "清空"
		handleClearBtn() {
            this.clearSP_();
			this.class1 = this.class2 = "";
            this.handleSearch()
		},

		// 获取列表数据
		async fetchData() {
			if (this.loading) return;
			this.loading = true;

			const data = {...this.extractParams()};
			if (this.searchcategoryid) data.searchcategoryid = this.searchcategoryid;
			this.$axios({
				url: "api/goodsspecs/queryGoodsSpecsList",
				method: "post",
				data
			})
			.then(res => {
				if (res.code === 2000) {
					this.total = res.data.count;
					const list = res.data.goodsSpecsList.map(item => {
						return {
							...item
						}
					});
					this.tableData.splice(0, this.tableData.length, ...list);

				} else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},

		// 获取商品分类配置
		fetchCategories (pid = 0) {
			this.$axios({
				url: "api/category/queryCategoryById",
				method: "post",
				data: {pid}
			})

			.then(res => {
				if (res.data?.list) {
					const cate = pid ? this.category2 : this.category1;
					cate.splice(0, cate.length, ...res.data.list)
				} else {
					return Promise.reject(res);
				}
			})

			.catch(reason => console.log(reason))
			
		},


        // 点击 "添加规格"
        handleAddBtn() {
            this.$router.push("/goods/specification/add");
        },

        // 点击表格行操作按钮: 编辑
        handleEdit(data) {
            this.$router.push("/goods/specification/edit/" + (data.id || 5));
        },

        // 点击表格行操作按钮: 删除
        handleDelete(data) {
			this.$confirm("要删除这个规格吗")

                .then(() => {
					this.loading = true;

					return this.$axios({
						url: "api/goodsspecs/deleteGoodsSpecs",
						method: "post",
						data: { id : data.id }
					})
				})

				.then(res => {
					const success = 2000 == res.code;

					this.$message({
						message: success ? "删除成功" : (res.msg || "删除失败"),
						type: success ? "success" : "warning"
					})

					this.loading = false;
					if (success) this.fetchData();
				})

                .catch(reason => {
                    console.warn(reason);
					this.loading = false;
                })
        }

    },

	created () {
		this.fetchCategories();
		this.auth.save = this.$hasAuthFor("api/goodsspecs/saveGoodsSpecs");
		this.auth.detail = this.$hasAuthFor("api/goodsspecs/queryGoodsSpecsInfo");
		this.auth.delete = this.$hasAuthFor("api/goodsspecs/deleteGoodsSpecs");
	},

	watch: {
		class1 (val) {
			this.class2 = "";
			this.category2.splice(0, this.category2.length);
			val && this.fetchCategories(val)
		}
	}
}