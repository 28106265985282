import forList from "@/mixin/forList"
import EditableState from "@/components/EditableState.vue"

export default {
    name: 'GoodsCategory',
    mixins: [forList],
    components: {
        "editable-state": EditableState
    },

    data () {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                searchname: "",
                searchpid: 0
            },
            whetherEnum: [ // 是否的配置
                // {name: "是", value: 1},
                // {name: "否", value: 2}
            ],
            allCategory: [], // 全部分类
            tree: [{
                name: "全部",
                id: 0,
                children: []
            }],
            treeProps: {
                children: 'children',
                label: 'name'
            },
        }
    },

    computed: {},

    methods: {

		// 点击 "清空"
		handleClear() {
            this.clearSP_();
            this.handleSearch();
            this.$refs.tree.setCurrentKey(0);
		},

        // 点击树的节点
        handleNodeClick(data) {
            this.SP_.searchpid = data.id;
            this.updateSP_toSP();
            this.SP.pageNum = 1;
            this.fetchData(data);
        },

        // 获取表格数据
        fetchData (treeRoot) {
            this.loading = true;
            this.fetchTree()
            .then(() => this.$axios({
                url: "api/category/queryCategoryPageList",
                method: "post",
                data: {...this.extractParams()}
            }))

            .then(res => {
                if (res.code === 2000) {
                    /**
                     * treeRoot 是查询的节点，查询出的数据需要更新至树中的 treeRoot 节点下，同时，再查询出的数据中
                     * 添加 parent 属性指向它的父节点
                     * 添加 parentName 保存它的父节点名称，这个属性用在表格中展示
                     */
                    treeRoot = treeRoot || this.tree[0];

                    this.total = res.data.count;
                    this.tableData.splice(0, this.tableData.length, ...res.data.categoryList.map(item => {
                        item.parentName = item.pid ? this.allCategory.find(cate => cate.id === item.pid)?.name : ""
                        return item
                    }));

                } else if (res.code !== 1003) {
                    return Promise.reject(res.msg)
                }
            })

            .catch(reason => {
                if (reason.code !== 1003) this.$message({
                    message: reason || "获取数据失败，请稍后再试",
                    type: "warning"
                }),
                console.warn(reason);
            })

            .then(() => {
                this.loading = false
            })
        },

        // 获取树（全部分类）
        fetchTree () {
            const treeRoot = this.tree[0];
            if (treeRoot.children && treeRoot.children.length) return Promise.resolve();

            return this.$axios({
                url: "api/category/queryCategoryList",
                method: "post",
                data: {searchpid: 0}
            })
            .then(res => {
                if (res.code === 2000) {
                    this.allCategory = res.data.categoryList;
                    // 更新 tree 数据
                    const {extractive} = (function productTree(list = res.data.categoryList, parent = treeRoot) {
                        let {extractive, rest} = list.extract(item => item.pid === parent.id);

                        extractive.forEach(item => {
                            // item.parent = parent;
                            // item.parentName = parent.name == "全部" ? "" : parent.name;
                            if (rest.length) {
                                const result = productTree(rest, item);
                                item.children = result.extractive;
                                rest = result.rest;
                            }
                        })

                        return {extractive, rest};
                    }())
                    treeRoot.children.splice(0, treeRoot.children.length, ...extractive)
                    return Promise.resolve();

                } else if (res.code !== 1003) {
                    return Promise.reject(res)
                }
            })
            .catch(reason => Promise.reject(reason))
        },

        // 切换开关
        handleSwitch(value, scope, key) {
            const actions = {
                is_temp: {
                    url: "api/category/updateTempState",
                    key: "tempState",
                    loadingKey: "setTemping",
                    label: "是否需要模板"
                },
                is_mail: {
                    url: "api/category/updateMailState",
                    key: "mailState",
                    loadingKey: "setMailing",
                    label: "是否需要邮寄"
                },
                status: {
                    url: "api/category/updateState",
                    key: "state",
                    loadingKey: "setStating",
                    label: "状态"
                }
            };
            const action = actions[key];

            if (!action.url) return;
            // scope.row[action.loadingKey] = true;
            this.loading = true;
            this.$axios({
                url: action.url,
                method: "post",
                data: {
                    id: scope.row.id,
                    [action.key]: value
                }
            })

            .then(res => {
                const success = 2000 == res.code;
                if (success) {
                    scope.row[key] = value;
                    this.$message({
                        message: `修改商品分类${action.label}成功`,
                        type: "success"
                    })
                }

                return success || Promise.reject(res.msg)
            })

            .catch(reason => {
                this.$message({
                    message: reason || "操作失败，请稍后重试",
                    type: "warning"
                })
            })

            .then(() => {
                // scope.row[action.loadingKey] = false;
                // this.$set(this.tableData, scope.$index, scope.row)
                this.loading = false
            })

        },

		// 点击 "新增菜单"
		handleAddBtn() {
			this.$router.push("/goods/category/add");
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(data) {
			this.$router.push("/goods/category/edit/" + (data.id || 5));
		},

		// 点击表格行操作按钮: 删除
		handleDelete(data) {
			this.$confirm("要删除这个商品分类吗")

                .then(() => {
					this.loading = true;

					return this.$axios({
						url: "api/category/deleteCategory",
						method: "post",
						data: { id : data.id }
					})
				})

				.then(res => {
					const success = 2000 == res.code;

					this.$message({
						message: success ? "删除成功" : (res.msg || "删除失败"),
						type: success ? "success" : "warning"
					})

					this.loading = false;
					if (success) {
                        this.$set(this.tree[0], "children", []);
                        this.fetchData();
                    }
				})

                .catch(reason => {
                    console.warn(reason);
					this.loading = false;
                })
		},

    },

    created () {
		this.auth.save = this.$hasAuthFor("api/category/saveCategory");
		this.auth.detail = this.$hasAuthFor("api/category/queryCategoryInfo");
		this.auth.delete = this.$hasAuthFor("api/category/deleteCategory");
		this.auth.switch = this.$hasAuthFor("api/category/updateState");
        // this.fetchTree()
    }
}