import forList from "@/mixin/forList";
import ThumbnailList from "@/components/ThumbnailList.vue";
import Phones from "@/components/Phones.vue";

const levelConfig = [
    {name: "非常差", value: 1},
    {name: "很差",   value: 2},
    {name: "一般",   value: 3},
    {name: "很好",   value: 4},
    {name: "非常好", value: 5},
];

export default {
    name: 'commentList',
    mixins: [forList],
    components: { ThumbnailList, Phones },

    data () {
        return {
            levelConfig,
            loading: false,
            SP_: {
                searchGoodsName : "",
                searchLevel  : "",
                searchContent: ""
            },
            phoneList: [],
        }
    },

    methods: {
        //查看电话号码
        handlePhoneParam(row) {
            this.loading = true;
            let _phoneList = []
            this.$axios({
                url: "api/customer/queryAllPhone",
                params: {
                    id: row.custId
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        for (const key in res.data.data) {
                            if (res.data.data[key]) {
                                _phoneList.push(res.data.data[key])
                            }
                        }
                        this.phoneList = _phoneList
                        this.loading = false;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: "获取电话号码失败",
                        type: "error"
                    })
                    console.warn(reason)
                    this.loading = false;
                })
        },
        handleOrderDetail(row) {
            this.$router.push(`/order/list/${row.order_id}/${null}`);
        },

        // 获取列表数据
        async fetchData() {
            if (this.loading) return;
            this.loading = true;
            const params = this.extractParams(); // 格式化查询参数

            try {
                const res = await this.$axios({
                    url: "api/evaluate/queryOrderEvaluateList",
                    params
                });
    
                if (res.code === 2000) {
                    const list = res.data?.orderEvaluateList.map(item => {
                        let arr = JSON.parse(item.path);
                        if (arr instanceof Array) arr = arr.map(path =>
                            this.$baseURL + path);
                        else arr = [];
                        item.path = arr;
                        return item;
                    });
                    if (list) {
                        this.tableData = list;
                        this.total = res.data.count;
                    }

                } else if (res.code !== 1003) {
                    return this.$message({
                        message: res.msg || "获取列表失败",
                        type: "warning"
                    })
                }

                this.loading = false;
            } catch (reason) {
                console.warn(reason);
                this.loading = false;
            }

        },


        // 批量操作：删除、上架、下架
        async handleBulkButton (where = "hide") {
            // if (where === "delete" && this.checkedlist.some(item => {
            //     if (item.isSale == 1) {
            //         _item = item;
            //         return true
            //     }
            //     return false
            // })) {
            //     return this.$alert(`包含已上架商品，无法批量删除`, "提示");
            // }
            const operationName = where == "delete" ? "删除" : "隐藏";
            try {
                await this.$confirm(`确定要${operationName}选中的评论吗`);
                this.loading = true;
                let res;
                if (where == "delete") res = await this.bulkDelete();
                else res = await this.bulkHide();
                
                if (res.code !== 2000 && res.code !== 1003) throw res;
                this.loading = false;
                this.$message({
                    message: `${operationName}成功`,
                    type: "success"
                })
                this.fetchData();

            } catch (reason) {
                this.loading = false;
                this.$message({
                    message: reason.msn || `${operationName}失败`,
                    type: "error"
                });
                console.warn("操作失败", reason);
            }
        },

        bulkDelete () {
            const ids = this.checkedlist.map(({id})=>id).join();
            return this.$axios({
                method: "post",
                url: "api/evaluate/deleteOrderEvaluate",
                data: {ids}
            })
        },
        bulkHide () {
            const ids = this.checkedlist.map(({id})=>id).join();
            return this.$axios({
                method: "post",
                url: "api/evaluate/hideOrderEvaluate",
                data: {ids}
            })
        },
    },

    created() {
        this.auth.hide    = this.$hasAuthFor("api/evaluate/hideOrderEvaluate");
        this.auth.delete  = this.$hasAuthFor("api/evaluate/deleteOrderEvaluate");
        this.auth.seePhone = this.$hasAuthFor("/api/customer/queryAllPhone");
    }
}