/**
 * 这是富文本编辑器的统一配置
 */

// 工具栏配置
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],    // toggled buttons
    ['blockquote', 'code-block'],
  
    [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],     // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],         // outdent/indent
    [{'direction': 'rtl'}],                       // text direction
  
    [{'size': ['small', false, 'large', 'huge']}],// custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],
  
    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'font': []}],
    [{'align': []}],
    ['link', 'image', 'video'],
    ['clean']                                     // remove formatting button
];

export default {
    modules: {
        toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
                'image': function (value) {
                    if (value) {
                        // 调用iview图片上传
                        document.querySelector("#hideuploadbtn").click()
                    } else {
                        this.quill.format('image', false);
                    }
                }
            }
        },
    },
    placeholder: "请输入"
}