
import ModeSelector from "../ModeSelector.vue";
export default {
    name: 'BindMode',
    components: {
        "mode-selector": ModeSelector
    },

    data () {
        return {
            show: false,     // 自身（弹窗）显示状态
            loading: false,
            tabList: ["按商品", "按规格"],
            curTab: 0,       // 当前tab index
            auth: {
                bind: false,   // 保存权限
                unbind: false  // 解绑权限
            },

            //#region 按商品
            PB: {
                goodsId: "",   // 商品id
                tempId: "",    // 模版id
            },
            modeDetail: [],    // 模板的配置详情
            tempLoading: false,// 正在加载模板详情
            //#endregion



            //#region 按规格
            isType: 1,            // 1单规格，2多规格
            productName: "",      // 商品名字
            detailList: [],       // 规格列表
            checkedlist: [],      // 选中的数据
            showSelectWin: false, // 模板选择窗,
            selectedMode: null,   // 选中的模板
            //#endregion
        }
    },

    methods: {

        // 显示商品的预览信息（父组件调用）
        async open (item) {
            this.show = true;
            let id = typeof item == "object" ? item.id : item;
                id = Number(id);
            this.PB.goodsId = id;
            this.isType = item.is_type;
            this.productName = item.name;
        },

        // 切换tab
        handleTabClick (index) {
            const {curTab} = this;
            if (curTab != index) {
                this.curTab = index;
                if (index == 1 && !this.detailList.length) {
                    this.fetchDetailList()
                }
            }
        },

        // 模板选中项改变
        async handleModeChange(id) {
            this.PB.tempId = id;
            if (id) {
                this.tempLoading = true;
                this.fetchModeInfo(id)
                .catch(() => this.fetchModeInfo(id))
                .catch(reason => {
                    console.warn(reason);
                    this.$message({
                        message: "获取模板配置失败，请稍后再试",
                        type: "warning"
                    });
                })
                .then(() => this.tempLoading = false);
            }
        },

        // 发送请求，获取模板信息
        fetchModeInfo (id) {
            if (!id) return Promise.reject("没有传入id，不能获模板信息");
            // return new Promise((resolve, reject) => {
                return this.$axios({
                    url: "api/ordertemp/queryOrderTempInfo",
                    method: "post",
                    data: {id}
                })

                .then(res => {
                    if (2000 === res.code) {
                        const {detaillist, tOrderTemp} = res.data;
                        // (弹窗)如果已经关闭，PB 应该已经被清空
                        if (tOrderTemp.id == this.PB?.tempId) {
                            const {modeDetail} = this;
                            modeDetail.splice(0, modeDetail.length,
                            ...detaillist.filter(item => item!=null).sort((p,n) =>
                                p.sort - n.sort).map(item => {

                                const _item = {
                                    value: item.type == 3 || item.type == 8 ? [] : ""
                                };
                                for (let key in item) _item[key.toCamel()] = item[key]
                                return _item;
                            }));
                        }
                        // resolve()
                    } else return Promise.reject(res)
                })
                // .catch(reason => {
                //     reject (reason);
                //     this.loading = false;
                // })
            // })
        },

        // 发送请求，获取规格列表
        fetchDetailList () {
            const {goodsId} = this.PB;
            if (!goodsId) return console.warn("没有商品id，无法获取规格列表");
            
            this.loading = true;
            this.$get(`/api/goods/getTempDetail?goodsId=${goodsId}`)
            .catch(() => this.$get(`/api/goods/getTempDetail?goodsId=${goodsId}`))
            .then(res => {
                if (res.code === 2000) {
                    const {detailList} = this; // 本地列表
                    const list = res.data.detailList.filter(item => !!item);
                    detailList.splice(0, detailList.length, ...list.map(item => {
                        item.detailname = item.detailname || this.productName;
                        return item;
                    }));

                } else if (res.code !== 1003) {
                    return Promise.reject(res)
                }
            })
            .catch(reason => {
                console.warn(reason);
                this.$message({
                    message: "获取商品规格配置失败，请稍后重试",
                    type: "warning"
                })
            })
            .then(() => this.loading = false);
        },

        // 表格选中事件，更新 data
        handleSelectionChange(e) {
            this.checkedlist.splice(0, this.checkedlist.length, ...e);
        },

        // 点击批量修改模板
        handleBatchBtn () {
            this.showSelectWin = true;
        },

        // 小窗选中
        handleModeSelect (id, item) {
            this.selectedMode = item
        },

        // 小窗保存
        handConfirmButton() {
            const {checkedlist, selectedMode} = this;
            this.loading = true;
            const data = {
                tempId: selectedMode.id,
                detailIds: checkedlist.map(item => item.id).join()
            }
            this.$axios({ url: "api/goods/bindTemp", method: "post", data })
            .catch(() => this.$axios({ url: "api/goods/bindTemp", method: "post", data }))
            .then (res => {
                if (res.code === 2000) {
                    checkedlist.forEach((item, index) => {
                        item.tempId = selectedMode.id;
                        item.tempname = selectedMode.name;
                        index;
                    });
                    checkedlist.splice(0, checkedlist.length)
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    this.showSelectWin = false;
                } else if (res.code !== 1003) {
                    return Promise.reject(res)
                }
            })
            .catch(reason => {
                console.warn(reason);
                this.$message({
                    message: "保存失败，请稍后重试",
                    type: "warning"
                })
            })
            .then(() => this.loading = false);
        },

        // 点击关闭按钮
        handleClose () {
            this.resetState();
            this.show = false;
        },

        // 重置所有是数据
        resetState () {
            this.curTab = 0;
            this.PB.goodsId = "";
            this.PB.tempId = "";
            this.modeDetail.splice(0, this.modeDetail.length);
            this.detailList.splice(0, this.detailList.length);
            this.checkedlist.splice(0, this.checkedlist.length);
            this.$refs.mode_selector1?.reset() 
            this.$refs.mode_selector2?.reset();
        },

        // 点击保存
        handSubmitButton () {
            this.loading = true;
            const data = this.PB;
            this.$axios({ url: "api/goods/bindTemp", method: "post", data })
            .catch(() => this.$axios({ url: "api/goods/bindTemp", method: "post", data }))
            .then (res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    // this.curTab = 1;
                    // this.fetchDetailList();
                    this.show = false;
                } else if (res.code !== 1003) {
                    return Promise.reject(res)
                }
            })
            .catch(reason => {
                console.warn(reason);
                this.$message({
                    message: "保存失败，请稍后重试",
                    type: "warning"
                })
            })
            .then(() => this.loading = false);
        },

        // 商品解绑模板
        handleUnbind () {
            const {goodsId} = this.PB;
            if (!goodsId) return console.warn("没有商品id，无法解绑");
            this.loading = true;
            this.$confirm("确定要解绑商品的模板吗", "提示")
            .then(() => this.$axios({
                url: "api/goods/unboundTemp",
                method: "post",
                data: { goodsId }
            }))
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "解绑成功",
                        type: "success"
                    })
                    this.show = false
                } else if (res.code !== 1003) {
                    return Promise.reject(res)
                }
            })
            .catch(reason => {
                console.warn(reason);
                this.$message({
                    message: "解绑失败，请稍后重试",
                    type: "warning"
                })
            })
            .then(() => this.loading = false)
        }

    },

    created () {
        // this.open(27); // 测试
        // this.fetchDetailList();
		this.auth.bind = this.$hasAuthFor("api/goods/bindTemp");
		this.auth.unbind = this.$hasAuthFor("api/goods/unboundTemp");
    }
}