import forEdit from "@/mixin/forEdit"
import PhonePreview from "@/components/PhonePreview/PhonePreview.vue"
import {nanoid} from "nanoid" // nanoid() 可以生成一个唯一的字符串 => 类似于"V1StGXR8_Z5jdHi6B-myT"

const createNewItem = () => ({
    id: undefined,
    value: "",
    nanoid: nanoid()
});

export default {
    name: 'SpecificationEdit',
    mixins: [forEdit],
    components: {
        "phone-preview": PhonePreview
    },

    data () {
        return {
            // post body 请求体数据
            PB: {
                id: undefined,    // 编辑才有id
                name: "",         // 规格名称
                specsdetail: [],  // 属性列表
                categoryId1: "",  // 商品一级分类id
                categoryId2: "",  // 商品二级分类id
                desc: "",         // 备注信息
            },
            rules: {
                name: {
                    required: true,
                    message: "请输入规格名称"
                },
                categoryId1: {
                    required: true,
                    message: "请选择分类"
                },
                specification: {
                    validator: function(rule, val, cb) {
                        if (val.value) cb()
                        else cb("请输入属性值")
                    }
                }
            },
            category1: [],     // 一级分类的字典
            category2: [],     // 二级分类的字典
            activeItem: null,  // 模拟器中当前选中的属性
            auth: {            // 用户权限，见created
                save: false,
                delete: true
            },
        }
    },

    methods: {
        specUp(index) {//上移
            let arr = this.DEEPCLONE(this.PB.specsdetail)
            let item1 = this.DEEPCLONE(this.PB.specsdetail[index])
            let item2 = this.DEEPCLONE(this.PB.specsdetail[index - 1])
            arr[index] = item2
            arr[index - 1] = item1
            this.PB.specsdetail = arr
        },
        specDown(index) {//下移
            let arr = this.DEEPCLONE(this.PB.specsdetail)
            let item1 = this.DEEPCLONE(this.PB.specsdetail[index])
            let item2 = this.DEEPCLONE(this.PB.specsdetail[index + 1])
            arr[index] = item2
            arr[index + 1] = item1
            this.PB.specsdetail = arr
        },
        // specDown

        // 获取页面数据
        fetchData() {
			if (this.loading) return;
			this.loading = true;

			this.$axios({
				url: "api/goodsspecs/queryGoodsSpecsInfo",
				method: "post",
				data: { id: this.PB.id }
			})
            .then(res => {
                if (res.code === 2000) {
                    const {parentcategory, childcategory} = res.data;
                    this.category1.splice(0, this.category1.length, ...(parentcategory || []))
                    this.category2.splice(0, this.category1.length, ...(childcategory || []))
                    
                    this.PB.id = res.data.specsInfo.id;
                    this.PB.name = res.data.specsInfo.name;
                    this.PB.desc = res.data.specsInfo.desc;
                    
                    /**
                     * res.data.specsInfo 中，pid 保存了一级分类选中的值，category_id 保存了
                     * 二级分类选中的值。例外的是，如果 pid 为0, category_id 中保存的值是一级
                     * 分类的中值，并且没有二级分类的选中值（二级分类是可选的）
                     */
                    const pid = res.data.specsInfo.pid
                    if (pid) {
                        this.PB.categoryId1 = pid;
                        /**
                         * 一级分类改变的监听器会情况二级分类的选中值，延迟给二级分类选中值赋值
                         * 可以避免被监听器清除的情况
                         */
                        // setTimeout(() => {
                            this.PB.categoryId2 = res.data.specsInfo.category_id;
                        // }, 300)
                    } else {
                        const categoryId1 = res.data.specsInfo.category_id;
                        this.PB.categoryId1 = categoryId1;
                        // 有时候后台没有返回二级分类，这时要主动查询一下
                        // console.log("二级分类", childcategory)
                        if (!childcategory) this.fetchCategories(categoryId1);
                    }
    
                    this.PB.specsdetail.splice(0, this.PB.length, ...res.data.tSpecsDetails);

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

		// 获取商品分类配置
		fetchCategories (pid = 0) {
			this.$axios({
				url: "api/category/queryCategoryById",
				method: "post",
				data: {pid}
			})

			.then(res => {
				if (res.data?.list) {
					const cate = pid ? this.category2 : this.category1;
					cate.splice(0, cate.length, ...res.data.list)
				} else {
					return Promise.reject(res);
				}
			})

			.catch(reason => console.log(reason))
			
		},

        // type 改变时，重新验证optionValue
        handleTypeChange () {
            this.$refs.form.validate("optionValue")
                .catch(reason => console.log(reason))
        },

        // 选择一级分类后，重置二级分类
		handleCate1Change (val) {
			this.PB.categoryId2 = "";
			this.category2.splice(0, this.category2.length);
			val && this.fetchCategories(val)
		},

        // 点击按钮: "添加属性"
        handleAddBtn() {
            // this.$refs.form.validate()

            // .then(this.addItem)
            // .catch(reason => {
            //     this.$message({
            //         message: "请填写完整再添加新字段",
            //         type: "warning"
            //     })
            //     console.warn(reason)}
            // )
            this.addItem()
        },

        // 新增一个字段
        addItem() {
            this.PB.specsdetail.push(createNewItem())
        },

        // 点击删除按钮
        handleRemoveBtn(index) {
            this.$confirm("要移除这个属性吗")
            // .catch(reason => console.warn(reason))

            .then(res => {
                // return console.log("是否移除属性", res)
                const list = this.PB.specsdetail;
                let data = list[index];
                if (data.id) {
                    this.loading = true;
                    return this.$axios({
                        url: "api/goodsspecs/deleteGoodsSpecsDetail",
                        method: "post",
                        data: {id: data.id}
                    })
                } else {
                    return Promise.resolve({ code: 2000 })
                }
            })

            .then(res => {
                if (res.code === 2000) {
                    this.PB.specsdetail.splice(index, 1);
                } else if (res.code !== 1003) {
                    this.$message({
                        message: res.msg || "删除失败",
                        type: "warning"
                    })
                }
                this.loading = false
            })
            .catch(reason => console.warn(reason))

            
        },

        // 点击提交按钮
        handleSubmitButton () {

            this.$refs.form.validate()

            .then(() => {
                this.posting = true;
                
                const {id, name, desc, categoryId1, categoryId2} = this.PB;
                return this.$axios({
                    url: "api/goodsspecs/saveGoodsSpecs",
                    method: "post",
                    data: {
                        id, name, desc,
                        categoryId: categoryId2 || categoryId1,
                        specsdetail: JSON.stringify(this.PB.specsdetail.map((item, index) => {
                            item.sort = index + 1
                            // 移除自增字段的本地id
                            const { id, value, sort } = item
                            return { id, value, sort }
                        }))
                    }

                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "保存成功" : res.msg || "保存失败",
                    type: success ? "success" : "warn"
                });

                if (success) {
                    this.handleNavigationBack()
                }
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: "保存失败",
                    type: "error"
                });
                console.warn(reason);
            })
            
            .then(() => {
                this.posting = false
            })
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        },

        // 模拟器中选项被点击
        handleSpecsClick(item) {
            this.activeItem = item
        }
    },

    created () {
        // 有 id 的情况，在 @/mixin/forEdit 已经处理了，
        // 这个没有 id 也发送请求的目的是获取 岗位、系统 的配置信息
        const id = Number(this.$route.params.id);
        if (!id) {
            // this.fetchData(true);
            this.addItem(),
            this.fetchCategories()
        }
		this.auth.save = this.$hasAuthFor("api/goodsspecs/saveGoodsSpecs");
		this.auth.delete = this.$hasAuthFor("api/goodsspecs/deleteGoodsSpecsDetail");
    }
}