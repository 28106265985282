import mixinFormSteps from "../mixinForSteps";
import editorOption from "@/utils/quillOpts";

export default {
    name: 'Step3',
    mixins: [mixinFormSteps],

    data () {
        return {
            PB: {
                id: undefined,  // 商品id（编辑时有，新增时无）
                goodsImg: [],   // 商品主图
                imgPaths: [],   // 商品图片地址
                desc: "",       // 商品描述
            },
            rules: {
                goodsImg: {
                    required: true,
                    message: "请上传商品主图"
                },
                imgPaths: {
                    required: true,
                    message: "请上传商品主图"
                },
                desc: [
                    {
                        required: true,
                        message: "请填写商品描述"
                    },
                    {
                        validator (rule, val, cb) {
                            if (val.length < 5) cb("请填写商品描述")
                            else cb()
                        }
                    }
                ]
            },
            editorOption,            // 富文本编辑器的配置
            pictures: [],            // 隐藏的 el-upload 的file
            uploadHeaders: {         // 图片上传的请求头
                token: this.$store.state.token
            }
        }
    },

    methods: {

        handleExceed(num = 1) {
          // 上传超过一张图
          this.$message({
            message: `只能上传${num}张图片`,
            type: "error",
          });
        },

        beforeFileUpload(file, key) {
        //   console.log(file);
          // 上传图标之前，判断图标格式
          const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
          const isLt2M = file.size / 1024 / 1024 < 5;
    
          if (!isImage) {
            this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传图片大小不能超过 5MB!');
          }
          if (isImage && isLt2M) {
            // this.userInterface.uploadIconProcess = true;
            if (key) {
                this.PB[key].push(file)
            }
            return true;
          }
          return false;
        },
        
        handleProgress(file, key) {
            this.posting = true;
            const index = this.PB[key].indexOf(file);
            this.$set(this.PB[key], index, file)
        },

        // 上传文件成功
        handleUploadSuccess (res, file, key) {
            this.posting = false;
            const index = this.PB[key].indexOf(file);
            if (res.code === 2000) {
                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.PB[key], index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
                this.$refs.form.clearValidate("img")
            } else {
                this.PB[key].splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },

        // 删除文件
        handleIconDelete (index, key) {
            this.PB[key].splice(index, 1);
        },

        // 富文本图片上传成功
        handleDescUploadSuccess (res) {
            this.$refs.uploader.clearFiles()
            if (res.code === 2000) {
                const {oldName, filePath} = res.data.files;

                const quill = this.$refs.editor.quill
                let length = quill.getSelection().index;
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, 'image', this.$baseURL + filePath)
                // 调整光标到最后
                quill.setSelection(length + 1)
            } else {
                this.$message({
                    message: "图片插入失败",
                    type: "error"
                })
            }
        },

        // 上传失败
        handleUploadFail () {
            this.$refs.uploader.clearFiles()
            this.$message({
                message: "图片插入失败",
                type: "error"
            })
        },

        // 更新 data 数据，(父组件获取商品详情后调用)
        setData (data = {}) {
            for (let key in data) {
                const _key = key.toCamel();
                const value = data[key];

                if (_key in this.PB) {
                    if (_key == "goodsImg" || _key == "imgPaths") {
                        this.PB[_key] = value.split(",").map(item => {
                            return {
                                path: item,
                                url: this.$baseURL + item
                            };
                        })
                    } else if (_key == "desc") {
                        this.PB[_key] = value.addHostToImg(this.$baseURL.slice(0, -1))
                    }
                    else this.PB[_key] = value

                } else if (_key in this.others) {
                    this.others[_key] = value
                }
            }
        },

        // 格式话本组件的数据，并返回
        getData () {
            const {goodsImg, imgPaths, desc, id} = this.PB;
            return {
                goodsImg: goodsImg.map(item => item.path).join(),
                imgPaths: imgPaths.map(item => item.path).join(),
                desc: desc.removeImgHost(this.$baseURL),
                id
            }
        },

        validate () {
            if (this.posting) {
                return Promise.reject({msg: "请等待图片上传完成"})
            } else {
                return this.$refs.form.validate()
            }
        }

    }
}