import forList from "@/mixin/forList";

export default {
    name: 'ServiceMerchant',
    mixins: [forList],

    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                // searchname: "",  // 搜索名称
                merchantName: "",  // 搜索名称
                status: -1,
            },
            statusArr: [
                { name: '全部', id: -1 },
                { name: '启动', id: 1 },
                { name: '禁用', id: 0 },
            ],
        };
    },

    computed: {


    },

    methods: {
        changeEnable(val, index, row) {
            this.loading = true;
            this.$axios({
                url: "api/merchant/updateServiceEnable",
                method: "post",
                data: { id: row.id, isEnable: val ? 1 : 0 }
            })

                .then(res => {
                    if (res.code == 2000) {
                        this.loading = false;
                        this.fetchData()
                    } else {
                        return Promise.reject(res);
                    }
                })

                .catch(reason => console.log(reason))

        },
        // 点击 "清空"
        handleClearBtn() {
            this.clearSP_();
            this.SP_.status = -1
            this.handleSearch()
        },

        // 获取列表数据
        async fetchData() {
            if (this.loading) return;
            this.loading = true;
            const params = { ...this.extractParams() };
            this.$axios({
                // url: "api/goodsspecs/queryGoodsSpecsList",
                url: "api/merchant/serviceMerchantList",
                // method: "post",
                method: "get",
                params
                // data
            })
                .then(res => {
                    if (res.code === 2000) {
                        this.total = res.data.count;
                        res.data.merchantList.map(item => {
                            item.emailAddrArr = []
                            item.emailAddrArr = item.emailAddr.split(',')
                            if (item.isEnable == 1) {
                                item.isEnableSy = true
                            } else {
                                item.isEnableSy = false
                            }

                        })
                        this.tableData = res.data.merchantList
                        console.log(this.tableData);
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    console.warn("获取数据失败", reason);
                    this.$message({
                        message: reason.msg || "获取数据失败",
                        type: "warning"
                    });
                })
                .then(() => this.loading = false)
        },




        // 点击 "添加服务商"
        handleAddBtn() {
            this.$router.push("/goods/serviceMerchant/add");
        },

        // 点击表格行操作按钮: 编辑
        handleEdit(data) {
            this.$router.push("/goods/serviceMerchant/edit/" + (data.id || 5));
        },

        // 点击表格行操作按钮: 删除
        handleDelete(data) {
            this.$confirm("要删除这个服务商吗")

                .then(() => {
                    this.loading = true;

                    return this.$axios({
                        url: "api/merchant/deleteServiceMerchant",
                        method: "post",
                        data: { id: data.id }
                    })
                })

                .then(res => {
                    const success = 2000 == res.code;

                    this.$message({
                        message: success ? "删除成功" : (res.msg || "删除失败"),
                        type: success ? "success" : "warning"
                    })

                    this.loading = false;
                    if (success) this.fetchData();
                })

                .catch(reason => {
                    console.warn(reason);
                    this.loading = false;
                })
        }

    },

    created() {
        this.auth.save = this.$hasAuthFor("api/merchant/saveOrUpdateMerchant");
        this.auth.detail = this.$hasAuthFor("api/merchant/updateServiceEnable");
        this.auth.delete = this.$hasAuthFor("api/merchant/deleteServiceMerchant");
    },
    watch: {

    }
}