import forEdit from "@/mixin/forEdit"
import GoodsPickList from "../../basic/voucherEdit/GoodsPickList.vue";



export default {
    name: 'ServiceMerchantEdit',
    mixins: [forEdit],
    components: {
        "goods-picker": GoodsPickList
    },

    data() {
        return {
            // post body 请求体数据
            PB: {
                id: null,    // 编辑才有id
                serviceName: '',//服务商名称
                contractPeople: '',//联系人
                contractPhone: '',//联系电话
                isSendEmail: 0, //是否接收邮件
                emailAddr: '',//邮件地址
                contractDatetime: '', //签约日期
                goods: [],      //商品
                remark: '',
            },
            tServiceMerchantGoodList: [],
            showAddPupop: false,     // 显示商品选择面板
            goodsList: [],           // 可选商品列表
            checkedGoods: [],        // 选择面板中已经勾选的商品



            category1: [],     // 一级分类的字典
            category2: [],     // 二级分类的字典
            activeItem: null,  // 模拟器中当前选中的属性
            auth: {            // 用户权限，见created
                save: false,
                delete: true
            },
            isrule: false,
        }
    },
    computed: {
        rules() {
            let _rules = {
                serviceName: {
                    required: true,
                    message: "请输入服务商名称"
                },
                contractPeople: {
                    required: true,
                    message: "请输入联系人"
                },
                contractPhone: {
                    required: true,
                    message: "请输入联系电话"
                },
                contractDatetime: {
                    required: true,
                    message: "请选择签约日期"
                },
                tServiceMerchantGood: [
                    { required: true, trigger: "blur", validator: this.validtServiceMerchantGood },
                ]

                // specification: {
                //     validator: function (rule, val, cb) {
                //         if (val.value) cb()
                //         else cb("请输入属性值")
                //     }
                // }
            }
            if (this.PB.isSendEmail == 1) {
                Object.assign(_rules,
                    {
                        emailAddr: [
                            {
                                // emailAddr: { required: true, message: "请输入邮件地址" },
                                required: true, trigger: "blur", validator: this.validtEmail
                            }
                        ]
                    }
                )
            }
            return _rules

        },

    },

    methods: {
        tradeLvInput(index) { // 0-100内两位小数
            this.tServiceMerchantGoodList[index].goodPrice = this.tServiceMerchantGoodList[index].goodPrice.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符   
            this.tServiceMerchantGoodList[index].goodPrice = this.tServiceMerchantGoodList[index].goodPrice.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的   
            this.tServiceMerchantGoodList[index].goodPrice = this.tServiceMerchantGoodList[index].goodPrice.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            this.tServiceMerchantGoodList[index].goodPrice = this.tServiceMerchantGoodList[index].goodPrice.replace(/^(\\\\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数     

            if (this.tServiceMerchantGoodList[index].goodPrice.indexOf(".") < 0 && this.tServiceMerchantGoodList[index].goodPrice != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
                this.tServiceMerchantGoodList[index].goodPrice = parseFloat(this.tServiceMerchantGoodList[index].goodPrice);
            }
            if (typeof (this.tServiceMerchantGoodList[index].goodPrice) === 'number') {
                if (this.tServiceMerchantGoodList[index].goodPrice >= 0 && this.tServiceMerchantGoodList[index].goodPrice <= 10000000) {
                    this.tServiceMerchantGoodList[index].goodPrice = Number(this.tServiceMerchantGoodList[index].goodPrice)
                } else {
                    this.tServiceMerchantGoodList[index].goodPrice = 0
                }
            }
        },
        validtServiceMerchantGood(rule, value, callback) {
            console.log(this.tServiceMerchantGoodList);
            if (this.tServiceMerchantGoodList.length == 0) {
                callback(new Error('至少选择一个商品'))
            } else {
                callback()
            }
        },
        validtEmail(rule, value, callback) {
            if (this.PB.emailAddr == '') {
                callback(new Error('请输入邮件地址'))
            } else {
                console.log(this.PB.emailAddr.indexOf(",") != -1);
                if (this.PB.emailAddr.indexOf(",") != -1) {
                    callback()
                } else { //只有一个邮箱时验证格式
                    if (this.validateEmail(this.PB.emailAddr)) {
                        console.log("Email 格式正确");
                        callback()
                    } else {
                        callback(new Error('邮件地址格式错误'))
                    }
                }
            }
        },
        validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },




        // 点击表格行操作按钮: 删除
        handleDelete(index, row) {
            if (this.tServiceMerchantGoodList.length == 1) {
                this.$message({
                    message: '至少保留一个商品',
                    type: "warning"
                });
                return
            }
            if (row.id) {
                this.$confirm("要删除这个供应商品吗")
                    .then(() => {
                        this.loading = true;
                        return this.$axios({
                            url: "api/merchant/deleteMerchantGood",
                            method: "post",
                            data: { id: row.id }
                        })
                    })

                    .then(res => {
                        const success = 2000 == res.code;

                        this.$message({
                            message: success ? "删除成功" : (res.msg || "删除失败"),
                            type: success ? "success" : "warning"
                        })

                        this.loading = false;
                        if (success) this.fetchData();
                    })

                    .catch(reason => {
                        console.warn(reason);
                        this.loading = false;
                    })
            } else {
                this.tServiceMerchantGoodList.splice(index, 1)
            }
        },
        changeRadio(val) {
            this.$refs.form.clearValidate();
        },

        specUp(index) {//上移
            let arr = this.DEEPCLONE(this.PB.specsdetail)
            let item1 = this.DEEPCLONE(this.PB.specsdetail[index])
            let item2 = this.DEEPCLONE(this.PB.specsdetail[index - 1])
            arr[index] = item2
            arr[index - 1] = item1
            this.PB.specsdetail = arr
        },
        specDown(index) {//下移
            let arr = this.DEEPCLONE(this.PB.specsdetail)
            let item1 = this.DEEPCLONE(this.PB.specsdetail[index])
            let item2 = this.DEEPCLONE(this.PB.specsdetail[index + 1])
            arr[index] = item2
            arr[index + 1] = item1
            this.PB.specsdetail = arr
        },
        // specDown

        // 获取页面数据
        fetchData() {
            if (this.loading) return;
            this.loading = true;

            this.$axios({
                url: "api/merchant/queryServiceMerchantById",
                method: "get",
                params: { id: this.PB.id }
            })
                .then(res => {
                    if (res.code === 2000) {
                        this.PB.id = res.data.tServiceMerchant.id
                        this.PB.serviceName = res.data.tServiceMerchant.serviceName
                        this.PB.contractPeople = res.data.tServiceMerchant.contractPeople
                        this.PB.contractPhone = res.data.tServiceMerchant.contractPhone
                        this.PB.isSendEmail = res.data.tServiceMerchant.isSendEmail
                        this.PB.emailAddr = res.data.tServiceMerchant.emailAddr
                        this.PB.contractDatetime = res.data.tServiceMerchant.contractDatetime
                        this.PB.remark = res.data.tServiceMerchant.remark
                        res.data.tServiceMerchantGoods.forEach(item => {
                            item.goodNo = item.good_no
                            item.goodPrice = item.good_price
                            item.goodId = item.good_id
                            item.goodDetailId = item.good_detail_id
                        })
                        this.tServiceMerchantGoodList = res.data.tServiceMerchantGoods
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    console.warn("获取数据失败", reason);
                    this.$message({
                        message: reason.msg || "获取数据失败",
                        type: "warning"
                    });
                })
                .then(() => this.loading = false)
        },

        // 获取商品分类配置
        fetchCategories(pid = 0) {
            this.$axios({
                url: "api/category/queryCategoryById",
                method: "post",
                data: { pid }
            })

                .then(res => {
                    if (res.data?.list) {
                        const cate = pid ? this.category2 : this.category1;
                        cate.splice(0, cate.length, ...res.data.list)
                    } else {
                        return Promise.reject(res);
                    }
                })

                .catch(reason => console.log(reason))

        },

        // type 改变时，重新验证optionValue
        handleTypeChange() {
            this.$refs.form.validate("optionValue")
                .catch(reason => console.log(reason))
        },

        // 选择一级分类后，重置二级分类
        handleCate1Change(val) {
            this.PB.categoryId2 = "";
            this.category2.splice(0, this.category2.length);
            val && this.fetchCategories(val)
        },

        // 点击按钮: "供应商品"
        handleAddBtn() {
            this.showAddPupop = true;
        },
        handleDialogClose() {
            this.showAddPupop = false;
            this.checkedGoods.splice(0, this.checkedGoods.length);
        },
        // 勾选、取消勾选时
        handleGoodsPicker(goods) {
            this.checkedGoods.splice(0, this.checkedGoods.length, ...goods);
        },

        // 点击确定选择 goods
        handConfirmButton() {
            console.log(this.checkedGoods);
            // debugger
            if (!this.checkedGoods.length) return this.$message({
                message: "请选择商品",
                type: "error"
            });
            if (this.checkedGoods.some(g => !g.isSingle && !g.checked.length))
                return this.$message({
                    message: "请选择商品规格",
                    type: "error"
                });


            this.checkedGoods.forEach(element => {
                let finditem = ''
                for (let i = 0; i < element.checked.length; i++) {
                    finditem = element.specs.find(item => {
                        return item.detailid == element.checked[i]
                    })
                    this.tServiceMerchantGoodList.push(
                        {
                            "id": null,
                            "goodNo": element.good_sn,
                            "goodId": element.id,
                            "goodDetailId": finditem.detailid || '',
                            "goodPrice": '',
                            "skuname": finditem.skuname || '',
                            "goodName": element.name || '',
                        }
                    )
                }
            });
            this.showAddPupop = false;
            this.$refs.form.clearValidate();
            // console.log(this.tServiceMerchantGoodList);


            // this.loading = true;
            // return this.$oxios({
            //     url: "api/couponConf/saveCouponConfGoods",
            //     method: "post",
            //     params: {
            //         couponConfGoodsStr: JSON.stringify(this.checkedGoods.map(item => {
            //             const checkedList = item.checked.map(id => item.specs.find(s =>
            //                 s.detailid == id)).filter(Boolean)
            //             return {
            //                 couponconfid: this.PB.id,
            //                 goodsid: item.id,
            //                 skuVal: item.isSingle ? "" : checkedList.map(it => it.sku_val).join()
            //             }
            //         }))
            //     }
            // })
            //     .then(res => {
            //         if (res.code === 2000) {
            //             this.loading = false;
            //             const ids = res.data.confGoodsIdList;
            //             this.tempIdList.push(...ids);
            //             this.fetchGoodsList(this.tempIdList.join())
            //             this.handleDialogClose();
            //         } else if (res.code !== 1003) return Promise.reject(res)
            //     })
            //     .catch(reason => {
            //         this.$message({
            //             message: reason.msg || "保存失败",
            //             type: "error"
            //         });
            //         console.log("保存失败", reason)
            //         this.loading = false
            //     })
        },


        // 点击删除按钮
        handleRemoveBtn(index) {
            this.$confirm("要移除这个属性吗")
                // .catch(reason => console.warn(reason))

                .then(res => {
                    // return console.log("是否移除属性", res)
                    const list = this.PB.specsdetail;
                    let data = list[index];
                    if (data.id) {
                        this.loading = true;
                        return this.$axios({
                            url: "api/goodsspecs/deleteGoodsSpecsDetail",
                            method: "post",
                            data: { id: data.id }
                        })
                    } else {
                        return Promise.resolve({ code: 2000 })
                    }
                })

                .then(res => {
                    if (res.code === 2000) {
                        this.PB.specsdetail.splice(index, 1);
                    } else if (res.code !== 1003) {
                        this.$message({
                            message: res.msg || "删除失败",
                            type: "warning"
                        })
                    }
                    this.loading = false
                })
                .catch(reason => console.warn(reason))


        },

        // 点击提交按钮
        handleSubmitButton() {
            this.isrule = true
            let _hapEmpty = this.tServiceMerchantGoodList.findIndex(item => {
                return !item.goodPrice
            })
            console.log(_hapEmpty);
            if (_hapEmpty >= 0) {
                return
            }

            this.$refs.form.validate()
                .then(() => {
                    this.posting = true;
                    let tServiceMerchant = {}, tServiceMerchantGoodList = []
                    tServiceMerchant.id = this.PB.id
                    tServiceMerchant.serviceName = this.PB.serviceName
                    tServiceMerchant.contractPeople = this.PB.contractPeople
                    tServiceMerchant.contractPhone = this.PB.contractPhone
                    tServiceMerchant.isSendEmail = this.PB.isSendEmail
                    tServiceMerchant.emailAddr = this.PB.emailAddr
                    tServiceMerchant.contractDatetime = this.PB.contractDatetime
                    tServiceMerchant.remark = this.PB.remark
                    return this.$oxios({
                        url: "api/merchant/saveOrUpdateMerchant",
                        method: "post",
                        data: {
                            tServiceMerchant,
                            tServiceMerchantGoodList: this.tServiceMerchantGoodList
                        }

                    })
                })

                .then(res => {
                    const success = 2000 == res.code;
                    this.$message({
                        message: success ? "保存成功" : res.msg || "保存失败",
                        type: success ? "success" : "warn"
                    });

                    if (success) {
                        this.handleNavigationBack()
                    }
                })

                .catch(reason => {
                    // this.$refs.form.validate 验证失败的拒绝结果是值 false
                    // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                    reason && this.$message({
                        message: "保存失败",
                        type: "error"
                    });
                    console.warn(reason);
                })

                .then(() => {
                    this.posting = false
                })
        },

        // 点击返回
        handleNavigationBack() {
            this.$router.back();
        },


    },

    created() {
        // 有 id 的情况，在 @/mixin/forEdit 已经处理了，
        // 这个没有 id 也发送请求的目的是获取 岗位、系统 的配置信息
        const id = Number(this.$route.params.id);
        if (!id) {
            // this.fetchData(true);
            // this.addItem(),
            // this.fetchCategories()
        }
        this.auth.save = this.$hasAuthFor("api/merchant/saveOrUpdateMerchant");
        this.auth.delete = this.$hasAuthFor("api/merchant/deleteMerchantGood");
    }
}